@use '../../../../styles';

.about-service {
  &__company-deck {
    @include styles.mixins-style-flex-column;
    margin: 30px 0;

    &__title {
      @include styles.mixins-style-capitalize-first-word-only;
    }
  }
}
