@use '../../../../styles';

.request-overview-tabs {
  @include styles.mixins-style-flex-row;
  margin-bottom: 40px;
  min-width: 900px;

  .ant-btn.ant-btn-default {
    &.sub-tab {
      @include styles.mixins-style-flex-center;
      flex: 1 1 100%;
    }
  }
}
