@use '../../styles';

.edit-event-drawer {
    &__image-container {
        @include styles.mixins-style-width-height(100%, 262px);
        .card-logo {
            @include styles.mixins-style-width-height(100%, 100%);
            object-fit: contain;

            &.card-logo-cover {
                object-fit: contain;
            }
        }
        .empty-container {

          .empty-image  {
            @include styles.mixins-style-flex-center;
            @include styles.mixins-style-width-height(100%, 262px);
            background-color: styles.$variables-extended-light-blue;

            svg {
              @include styles.mixins-style-width-height(32px, 32px);

              path {
                color: styles.$variables-white;
              }
            }
          }
        }
    }
    &__evet-details {
      margin-top: 24px;
        &__label {
          @include styles.mixins-style-font-format(18px, 24px, 700, capitalize);
          color: styles.$variables-dark-grey;
        }

        &__categories {
          @include styles.mixins-style-flex-row;
          align-items: center;
          margin-bottom: 5px;
          gap: 6px;

          .category-tag {
            &__border-tag {
              background-color: styles.$variables-white;
              @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
              @include styles.mixins-style-font-format(14px, 22px, 500, capitalize);
              @include styles.mixins-style-width-height(fit-content, 30px);
              color: styles.$variables-primary-blue;
              justify-content: center;
              align-items: center;
              padding: 6px 12px;
              gap: 4px;
              display: flex;
            }

            &__blue-tag {
              @include styles.mixins-gradients-blue-gradient;
              @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 16px);
              color: styles.$variables-extended-dark-blue;
              @include styles.mixins-style-font-format(14px, 22px, 500, capitalize);
              @include styles.mixins-style-width-height(fit-content, 30px);
              justify-content: center;
              align-items: center;
              padding: 6px 12px;
              gap: 4px;
              display: flex;
            }
            
            &__purple-tag {
              @include styles.mixins-gradients-purple-gradient;
              @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-purple-stroke, 16px);
              color: styles.$variables-extended-purple;
              @include styles.mixins-style-font-format(14px, 22px, 500, capitalize);
              @include styles.mixins-style-width-height(fit-content, 30px);
              justify-content: center;
              align-items: center;
              padding: 6px 12px;
              gap: 4px;
              display: flex;
            }
          }
        }
    }
    .submit__wrap {
      @include styles.mixins-style-flex-row-end;
      margin-top: 10px;
      gap: 14px;    
      .secondary-delete-button{
        @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      }
    }
    .event-buttons {
      &__container {
        @include styles.mixins-style-flex-row-end;
        gap: 16px;

        .ant-select:not(.ant-select-customize-input) {
          .ant-select-selector {
            @include styles.mixins-style-width-height(123px, 32px);
            @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
            padding: 4px 16px;
            background: styles.$variables-white;
          }

          .ant-select-selection {
            &-placeholder, &-item {
              @include styles.mixins-style-font-format(14px, 24px, 700);
              color: styles.$variables-dark-grey;
            }

            &-item {
              @include styles.mixins-style-capitalize-first-word-only;
            }
          }

          .ant-select-arrow {
            color: styles.$variables-dark-grey;

            svg {
              @include styles.mixins-style-width-height(16px, 16px);
            }
          }
        }

        .event-response {
          &__interested,
          &__going,
          &__not-going {
            @include styles.mixins-style-font-format(14px, 24px, 700);
            @include styles.mixins-style-flex-row-v-center;
            padding: 4px 16px;
            gap: 8px;
            width: 123px;
          }

          &__interested {
            @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
            color: styles.$variables-dark-grey;
            background: styles.$variables-white;
          }

          &__going {
            @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 16px);
            color: styles.$variables-white;
            background: styles.$variables-primary-blue;
          }

          &__not-going {
            @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-error, 16px);
            color: styles.$variables-primary-error;
            background: styles.$variables-white;
          }
        }
      }
    }

    .event-buttons__rsvp-dropdown.ant-select-dropdown {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      box-shadow: styles.$variables-vertical-box-shadow;
      padding: 8px 0;

      .ant-select-item-option-content {
        @include styles.mixins-style-font-format(14px, 20px, 400);
        @include styles.mixins-style-capitalize-first-word-only;
        color: styles.$variables-dark-grey;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        .ant-select-item-option-content {
          color: styles.$variables-white;
        }
      }
    }
    .date-pickers {
      @include styles.mixins-style-flex-row-v-center;
      gap: 16px;
    .form-item-vertical {
      .ant-form-item-row {
        @include styles.mixins-style-flex-column;
        gap: 8px;
      }
      .ant-col.ant-form-item-control {
        @include styles.mixins-style-width-height(100%, auto);
        max-width: 100%;
      }

      .ant-col.ant-form-item-label {
        width: 100%;
      }
      .ant-form-item-label {
        color: styles.$variables-dark-grey;
        @include styles.mixins-style-font-format(24px, 24px, bold);
      }
    }

    .ant-picker {
    .ant-picker-suffix {
      svg {
        @include styles.mixins-style-width-height(16px, 16px);
      }
    }
  }

  .ant-picker.ant-picker-focused {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
    box-shadow: none;

    .ant-picker-suffix {
      color: styles.$variables-primary-blue;
    }
  }

  .ant-form-item-label label {
    @include styles.mixins-style-flex-row;
    gap: 8px;
    @include styles.mixins-style-font-format(18px, 24px, bold);
  }

  .ant-picker.ant-picker-disabled {
    &, &:hover {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-grey, 20px);
      background: styles.$variables-secondary-grey;
    }

    .ant-picker-suffix {
      color: styles.$variables-primary-grey;
    }
  }
  
  .ant-picker {
    &, &:not(.ant-input-affix-wrapper-disabled):hover {
      @include styles.mixins-style-width-height(100%, 40px);
      color: styles.$variables-dark-grey;
      @include styles.mixins-style-font-format(14px, 20px, 400);
    }

    &:focus {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
      background: styles.$variables-white;
      box-shadow: none;
    }

    &:not(.ant-input-disabled):not(:focus):not(.ant-picker-focused) {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      background: styles.$variables-white;
    }

    &.ant-input-disabled {
      &, &:hover {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-grey, 20px);
        background: styles.$variables-secondary-grey;
      }
    }
  }
    }
}