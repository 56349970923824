@use '../../../styles';

.event-card {
  @include styles.mixins-style-flex-row-space-between;
  padding: 16px;

  &-left {
    @include styles.mixins-style-flex-row-v-center;
    gap: 16px;
  }

  &.event-card-blue {
    @include styles.mixins-gradients-blue-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 16px);
  }

  &.event-card-green {
    @include styles.mixins-gradients-green-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-green-stroke, 16px);
  }

  &.event-card-purple {
    @include styles.mixins-gradients-purple-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-purple-stroke, 16px);
  }

  &__date {
    @include styles.mixins-style-width-height(40px, 40px);
    @include styles.mixins-style-flex-center-column;
    border-radius: 8px;
    gap: 6px;
    box-shadow: styles.$variables-spread-shadow;
    background: styles.$variables-white;

    &-day {
      height: 12px;
    }

    &-month {
      @include styles.mixins-style-font-format(12px, 16px, 400, uppercase);
      height: 10px;
    }
  }

  &__date-day, &__title {
    @include styles.mixins-style-font-format(16px, 24px, 700);
    margin: 0;
  }

  &__details {
    @include styles.mixins-style-flex-column;
    gap: 8px;
  }

  &__info {
    @include styles.mixins-style-flex-row;
    gap: 16px;
  }

  &__category {
    @include styles.mixins-style-font-format(14px, 16px, 300);
  }

  &__time {
    @include styles.mixins-style-font-format(14px, 16px, 600);
  }

  &__date-day,
  &__date-month,
  &__time,
  &__category,
  &__title {
    color: styles.$variables-dark-grey;
  }
}
