@use '../../../../styles';

.about_cohort_modal {
  &-title {
    text-align: start;
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-flex-start;
    margin-bottom: 0;
  }

  &-data {
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(14px, normal, normal, capitalize);
  }

  &__logo_container {
    max-width: 40%;
    max-height: 40%;
    border-radius: 8px;
    overflow: hidden;
    @include styles.mixins-style-flex-center;
    margin: auto;
    padding: 20px;

    .cohort__image {
      @include styles.mixins-style-width-height(100%, 100%);
      object-fit: contain;
    }
  
    .cover-image {
      object-fit: cover;
    }
  }
}

.ant-modal-wrap .ant-modal {
  width: 70% !important;
}

.ant-modal-header .ant-modal-title {
  .title-with-actions {
    gap: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ant-modal-content {
  padding: 20px;
}
