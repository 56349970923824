@use '../../../styles';

.title-with-actions__container,
.buttons-container {
  @include styles.mixins-style-flex-row;
}

.title-with-actions {
  @include styles.mixins-style-flex-column;
  flex: 1 1;
  gap: 10px;
  margin-bottom: 48px;

  &--top {
    @include styles.mixins-style-flex-row;
    width: 100%;
    gap: 32px;
  }

  &--left {
    @include styles.mixins-style-flex-column;
    flex: 1 1;
    gap: 13px;
  }

  .search-bar .filter-container {
    flex: 0 0;
    width: 100%;
  }

  .search-bar.search-bar-no-button {
    @include styles.mixins-style-border(none);
    background-color: transparent;
    width: fit-content;
    margin: 0;
    padding: 0;
  }

  & .title-container {
    :has(.text-m),
    :has(.text-l) {
      margin-bottom: 20px;
    }
  }

  &__container {
    @include styles.mixins-style-width-height(100%, auto);
    justify-content: space-between;

    .title,
    .title-container,
    .buttons-container .button {
      @include styles.mixins-style-flex-center;
    }

    .title-container {
      text-align: left;
    }

    .buttons-container {
      gap: 13px;

      &:not(.buttons-container--top){
        padding-right: 75px;
      }

      .button {
        background-color: styles.$variables-light-blue-2;
        border-color: styles.$variables-light-blue-2;
        color: styles.$variables-dark-blue-1;

        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}
