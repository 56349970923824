@use '../../../styles';

.submit-overview-data-container {
  &__title {
    @include styles.mixins-style-font-format(18px, normal, bold, capitalize);
    margin-bottom: 20px;
    text-align: left;
  }

  &__body {
    @include styles.mixins-style-flex-column;
    padding: 20px;
    align-items: center;

    &__no-data-text {
      max-width: 400px;
      font-size: 16px;
    }
  }
}
