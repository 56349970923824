@use '../../../../../../../styles';

.ant-modal.file-preview-modal {
    .ant-modal-body {
        background-color: styles.$variables-light-blue-1;

        .csv-preview {
            td {
                @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-3, 10px);
                @include styles.mixins-style-width-height(200px, 50px);
                padding: 10px;
                color: styles.$variables-dark-grey;
                @include styles.mixins-style-font-format(14px, 40px, 400, capitalize);
            }
        }
    }

    .file-preview-container {
        @include styles.mixins-style-width-height(100%, 100vh);
        @include styles.mixins-style-flex-center-column;
        padding-top: 20px;

        .html-preview {
            @include styles.mixins-style-width-height(642px, 760px);
            @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-3);
            background-color: white;
        }
    }
}
