@use '../../../styles';

.ant-drawer.drawer {
  z-index: 200;

  .ant-form {
    gap: 24px;
    height: 100%;

    &-item {
      margin: 0;
    }
  }

  .ant-drawer-close {
    margin: 8px 4px 0 0;
    padding: 0;

    svg {
      @include styles.mixins-style-width-height(24px, 24px);
      color: styles.$variables-dark-grey;
    }
  }

  .ant-drawer-body {
    padding: 24px 40px 40px;
  }

  .ant-drawer-content-wrapper {
    max-width: 50vw;
    min-width: 40vw;
    //width: unset !important;

    @media (max-width: 1000px) {
      max-width: 70vw;
    }
  }

  .ant-drawer-header {
    border-bottom: none;
    padding: 32px 40px;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-title {
    @include styles.mixins-style-font-format(32px, 40px, 900, capitalize);
    color: styles.$variables-dark-grey;
  }

  .ant-drawer-header-title {
    align-items: flex-start;
  }

  .ant-drawer-mask {
    background-color: #eaf2fe88;
  }

  &--hidden {
    display: none;
  }

  &--blue {
    .ant-drawer-header {
      background-color: styles.$variables-extended-light-blue;
    }
  }
}
