@use '../../../styles';

.requests {
  &__view-all-btn {
    @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
    @include styles.mixins-style-border(none);
    background-color: transparent;
    color: styles.$variables-dark-blue-2;
  }

  .overview {
    text-align: center;
    .text-xs{
      text-align: start !important;
    }
  }
}
