@use '../../../../../../../../styles';

.upload-invoice-fields {
  @include styles.mixins-style-flex-column;
  gap: 8px;
  margin-top: 16px;

  &__buttons {
    @include styles.mixins-style-flex-row-end;
    gap: 16px;
  }
}
