@use '../../styles';

.login_page{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .image_area {
    flex: 0 0 auto;
    width: 50%;
    background-image: url("https://www.hub71.com/svgs/icon0.svg");
    background-repeat: no-repeat;

    img{
      display: block;
      margin: 150px auto 0 auto;
    }
  }

  .form_area {
    flex: 0 0 auto;
    width: 50%
  }

  @media screen and (max-width: 920px){
    .image_area {
      flex: 0 0 auto;
      width: 100%;
    }

    .form_area {
      flex: 0 0 auto;
      width: 100%
    }
  }

  @media screen and (max-width: 819px){
    .image_area {
      display: none;
    }

    .login_page {
      display: block;
    }
  }
}

.login {
  &__logo {
    height: 50px;
    object-fit: contain;
    margin-bottom: 30px;
  }

  &__reset-password {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    &__link {
      color: styles.$variables-dark-blue-2;
      font-weight: 600;
      display: block;
    }
  }

  &__container {
    @include styles.mixins-style-flex-center-column;
    margin: 0 auto 0 auto;
    padding: 40px;
    max-width: 500px;
    background-color: styles.$variables-light-blue-2;

    .ant-form.ant-form-horizontal {
      width: 350px;
      margin: 0 auto;
    }

    &__submit-button,
    .ant-input {
      width: 100%;
    }

    &,
    &__submit-button,
    .ant-input {
      border-radius: 6px;
    }

    .ant-form-item-label label {
      color: styles.$variables-dark-blue-2;
      font-size: 12px;
    }

    .input:first-child {
      margin-bottom: 20px;
    }

    .input .ant-form-item-control-input-content {
      input {
        border: none;
        color: styles.$variables-dark-blue-2;

        &:-webkit-autofill {
          &, &:focus {
            // Needed to override internal-autofill-selected styling
            -webkit-box-shadow: 0 0 0 1000px styles.$variables-light-blue-3 inset;
            box-shadow: 0 0 0 1000px styles.$variables-light-blue-3 inset;
          }
        }
      }
    }

    .ant-btn.primary-button:not(.md) {
      margin-top: 40px;
      width: 100%;
    }

    .ant-col {
      padding: 0;
      margin: 0;
    }

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-form-item-explain-error {
      position: absolute;
    }

    .ant-form-item-label label.ant-form-item-required {
      &:before, &:after {
        content: unset;
      }
    }
  }
}
