@use '../../../styles';

button.add_recipient {
    @include styles.mixins-style-font-format(14px, normal, 700, uppercase);
    @include styles.mixins-style-width-height(auto !important, auto);
    padding: 0;
    color: styles.$variables-dark-blue-1;
    border: none;
    background: none;
    margin: 30px 0 30px 0;

    &:hover,
    &:active,
    &:focus {
        color: styles.$variables-dark-blue-1;
    }
}