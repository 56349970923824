@use '../../../../../../styles';

.wallet-transaction-overview {
  .overview .overview__card {
    @include styles.mixins-style-flex-column;

    .overview__card-data {
      width: 100%;
    }

    .overview-data-column {
      padding: 10px 0;
    }
  }
}
