@use '../../../../styles';

.event-tabs {
  @include styles.mixins-style-flex-column-end-center;
  width: 100%;
  gap: 42px;

  &__container {
    @include styles.mixins-style-flex-column;
    width: 100%;
    gap: 32px;
  }

  &__main, &__sub {
    @include styles.mixins-style-flex-row;
  }

  &__main {
    width: 100%;
  }

  .tab-text {
    @include styles.mixins-style-capitalize-first-word-only;
    margin: 0;
  }

  .event-sub-tabs__container {
    width: 100%;

    &:not(.no-event-sub-tabs) {
      @include styles.mixins-style-flex-row-space-between;
    }

    &.no-event-sub-tabs {
      @include styles.mixins-style-flex-row-end;
    }
  }
}
