@use '../../../../styles';

.details-drawer-row {
  @include styles.mixins-style-flex-row;
  gap: 8px;

  &__label {
    @include styles.mixins-style-font-format(16px, 24px, 700);
    color: styles.$variables-dark-grey;
  }

  &__item {
    @include styles.mixins-style-font-format(16px, 24px, 500);
    color: styles.$variables-dark-grey;
  }
}
