@use '../../../../../styles';

.drawer-title {
  @include styles.mixins-style-flex-column;
  gap: 16px;

  &__status {
    margin-bottom: 0;
    color: styles.$variables-dark-grey;
  }

  &__status, &__status-select {
    @include styles.mixins-style-font-format(16px, 16px, 400, capitalize);
  }

  &__status {
    display: flex;

    &-value {
      font-weight: 700;
      margin-left: 5px;
      display: block;
      text-transform: none;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &__status-select {
    @include styles.mixins-style-flex-row-v-center;
    gap: 10px;

    .ant-form-item {
      margin: 0;
    }

    .ant-select {
      min-width: 150px;

      .ant-select-selection-item::after {
        content: none;
      }
    }
  }

  &--new {
    color: styles.$variables-extended-dark-blue;
  }

  &--failed-to-deliver {
    color: styles.$variables-extended-red;
  }

  &--in-progress {
    color: styles.$variables-primary-info;
  }

  &--delivered {
    color: styles.$variables-primary-success;
  }

  &--info-required {
    color: styles.$variables-extended-purple;
  }

  &--pending, &--to-be-signed {
    color: styles.$variables-primary-warning;
  }

  &--rejected {
    color: styles.$variables-primary-error;
  }

  &--new,
  &--failed-to-deliver,
  &--in-progress,
  &--delivered,
  &--info-required,
  &--pending,
  &--to-be-signed,
  &--rejected {
    @include styles.mixins-style-capitalize-first-word-only;
    margin-bottom: 0;
  }

  &__selector {
    .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected,
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color:  styles.$variables-extended-light-blue !important;
      color: styles.$variables-white !important;
    }
  }
  
  .input .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .input .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
  }

  .ant-btn {
    text-transform: none !important;
  }
}
