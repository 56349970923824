@use '../../../styles';

.angel-investor-wallet {
  @include styles.mixins-style-flex-column;
  gap: 20px;

  &, &__container {
    width: 100%;
  }

  &__container {
    @include styles.mixins-style-flex-row-v-center;
    gap: 20px;

    .wallet-graph {
      position: relative;
      min-width: 330px;
      width: calc(50% - 20px);
      height: 313.5px;
      @include styles.mixins-style-flex-center-column;

      svg {
        overflow: visible;
      }

      &__centered-metric {
        @include styles.mixins-style-flex-center-column;
        position: absolute;
        z-index: 10;

        &--label {
          @include styles.mixins-style-font-format(14px, 20px, 400);
        }

        &--label, &--value {
          color: styles.$variables-primary-blue;
        }

        &--value {
          @include styles.mixins-style-font-format(18px, 24px, 700);
        }
      }

      text {
        font-family: 'Mulish', sans-serif!important;
      }

      g g {
        &:first-child {
          g:first-child {
            path {
              stroke: styles.$variables-extended-green-stroke!important;;
            }

            text {
              fill: styles.$variables-extended-green!important;
            }
          }

          g:nth-child(2) {
            path {
              stroke: styles.$variables-extended-dark-blue-stroke!important;
            }

            text {
              fill: styles.$variables-extended-dark-blue!important;
            }
          }

          g:nth-child(3) {
            path {
              stroke: styles.$variables-extended-red-stroke!important;
            }

            text {
              fill: styles.$variables-extended-red!important;
            }
          }
        }

        &:nth-child(2) {
          path {
            filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));

            &:first-child {
              stroke: styles.$variables-extended-green-stroke!important;
            }

            &:nth-child(2) {
              stroke: styles.$variables-extended-dark-blue-stroke!important;
            }

            &:nth-child(3) {
              stroke: styles.$variables-extended-red-stroke!important;
            }
          }
        }

        &:nth-child(3) {
          g:first-child {
            text {
              fill: styles.$variables-extended-green!important;
            }
          }

          g:nth-child(2) {
            text {
              fill: styles.$variables-extended-dark-blue!important;
            }
          }

          g:nth-child(3) {
            text {
              fill: styles.$variables-extended-red!important;
            }
          }
        }
      }

      &.wallet-consumed {
        g g {
          &:first-child {
            g:first-child {
              path {
                stroke: styles.$variables-extended-dark-blue-stroke!important;
              }

              text {
                fill: styles.$variables-extended-dark-blue!important;
              }
            }
          }

          &:nth-child(3) {
            g:first-child {
              text {
                fill: styles.$variables-extended-dark-blue!important;
              }
            }
          }
        }
      }

      text {
        @include styles.mixins-style-font-format(13px!important, 18px, 700);
      }
    }
  }

  &__title {
    @include styles.mixins-style-font-format(18px, normal, bold);
    color: styles.$variables-dark-grey;
    margin-bottom: 0;
    text-align: left;
  }

  &__header {
    @include styles.mixins-style-flex-row-space-between;
    width: 100%;
    flex-wrap: wrap;

    .title-with-actions {
      margin: 0;
    }
  }

  &__amount-info {
    // @include styles.mixins-style-flex-column-start;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex: 1 1 50%;
    min-width: 200px;

    &.angel-investor-wallet__amount-info--profile-view {
      width: 50%;
    }
  }

  &-with-button {
    @include styles.mixins-style-width-height(100%, auto);

    .angel-investor-wallet__amount-info {
      @include styles.mixins-style-flex-row-space-between;
    }
  }
}
