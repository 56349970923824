@use '../../../../../../../../styles';

.document-field {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 8px);
  @include styles.mixins-style-flex-row-space-between;
  padding: 10px 20px;
  margin: 10px 0;

  .input-field {
    max-width: 170px;
    min-width: 100px;
  }

  .upload-document{
    margin: 0 15px;

    .file-info--container{
      max-width: 160px;
      overflow: hidden;
    }
  }

  .action-buttons {
    display: flex;
    button{
      margin: 0 10px 0 0;
    }
    .discard-btn {
      color: rgba(0,0,0,.85);

      &:hover {
        color: #bb2124;
      }
    }
  }

  .ant-btn-ghost {
    border: none;
    padding: 0;
    margin: 0;

    svg {
      margin-top: 5px;
    }
  }
}
