@use '../../../../../../../../styles';

.agreement-field {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 8px);
  @include styles.mixins-style-flex-row-space-between;
  padding: 10px 20px;

  .ant-btn-ghost {
    border: none;
    padding: 0;
    margin: 0;

    svg {
      margin-top: 5px;
    }
  }
}
