@use '../../../../../styles';

.slider {
  .ant-slider-handle {
    background: styles.$variables-light-blue-6;
    width: 48px;
    opacity: 0;
    z-index: 1;
  }

  .ant-slider, .ant-slider-rail, .ant-slider-track {
    background-color: styles.$variables-dark-blue-2 !important;
  }
}
