@use '../../styles';

.request-service-details {
  @include styles.mixins-style-flex-column;
  gap: 24px;

  .switch-wrap {
    @include styles.mixins-style-flex-row-space-between;
    flex: none;
    flex-grow: 1;
    height: 24px;

    .request-service-details-description {
      @include styles.mixins-style-font-format(18px, 24px, 700);
      height: 24px;
    }
  }

  .dependant-container {
    background-color: styles.$variables-extended-light-blue;
    @include styles.mixins-style-flex-column-start;
    padding: 16px;
    gap: 24px;
    order: 1;
    border-radius: 16px;

    .ant-row.ant-form-item-row {
      gap: 8px;
    }

    .ant-form-item-no-colon {
      font-weight: bold;
      text-transform: none;
    }

    .ant-picker-input input {
      text-transform: none;
    }
  }

  .submit__wrap {
    @include styles.mixins-style-flex-row-end;
    gap: 16px;
    margin: 40px 0;
  }

  .attributes-container {
    margin: 0;

    .attributes-list {
      padding: 0;
      border: none;
      box-shadow: none;

      .attribute-container {
        .title {
          display: none;
        }
      }
    }
  }

  .overview-column.input {
    .ant-select.quantity-select {
      width: 100%;
    }
  }

  .dependant {
    margin-bottom: 16px;

    .ant-form-item-label label {
      @include styles.mixins-style-font-format(18px, 24px, 700);
    }
  }

  .terms-conditions {
    cursor: pointer;
    font-weight: 700;
    font-style: italic;
    text-decoration: underline;

    &:hover {
      color: #4e4efe
    }
  }
}

.input .ant-form-item-label label {
  @include styles.mixins-style-font-format(18px, 24px, 700);
}

.label1 {
  @include styles.mixins-style-font-format(18px, 24px, 700);
}

.alert_text{
  color: "red";
}
