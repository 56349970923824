@use '../../../../../../styles';

.program-overview {
  .overview__title {
    color: styles.$variables-dark-grey;
  }
  
  .overview {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
    background: styles.$variables-white;
    box-shadow: styles.$variables-vertical-box-shadow;
    padding: 16px;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;
  
    .overview__card {
      color: styles.$variables-dark-grey;
  
      .overview__card-data {
        width: 100%;
  
        .overview-data-column {
          .overview-data-label, .overview-data-text {
            color: styles.$variables-dark-grey;
          }
        }
      }
    }
  }
}

.ant-modal-wrap {
  .ant-modal-header {
    padding-bottom: 20px;
  }

  .ant-modal-body {
    padding-top: 20px;
  }

  .ant-modal-close {
    color: styles.$variables-dark-grey;
  }
}
