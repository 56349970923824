@use '../../../styles';

.stat-cards-container {
  @include styles.mixins-style-flex-center;
  gap: 10px;
  flex: 1 1;

  &.is-one-card {
    .stat-card {
      width: 50%;
    }
  }
}
