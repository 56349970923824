@use '../../../../styles';

.requested-by {
  &__container {
    @include styles.mixins-style-border(hidden, 0, transparent, 8px);
    @include styles.mixins-style-flex-row-space-between;
    background: styles.$variables-light-blue-2;
    width: 100%;
    padding: 20px;
  }

  &__contact-container {
    @include styles.mixins-style-flex-column-end-center;

    .requested-by__email, .requested-by__phone {
      text-decoration: underline;
    }
  }

  &__info-container {
    @include styles.mixins-style-flex-row-v-center;
    gap: 10px;

    .ant-avatar.requested-by__person-image {
      @include styles.mixins-style-width-height(64px, 64px);
    }

    .requested-by {
      &__name {
        @include styles.mixins-style-font-format(16px, normal, bold, capitalize);
        color: styles.$variables-dark-blue-2;
      }

      &__company-container {
        @include styles.mixins-style-flex-row-v-center;
        gap: 5px;

        .company-image,img{
          object-fit: contain;
        }

        .dot {
          @include styles.mixins-style-width-height(5px, 5px);
          background: styles.$variables-light-blue-4;
          border-radius: 50%;
        }
      }
    }
  }
}
