@use '../../../../../styles';

.ant-form.new-category-container {
  margin-bottom: 8rem;
  
  .ant-form-item.form-item.form-item-vertical {
    margin-bottom: 15px;
  }

  .input {
    flex-basis: 100%;
  }

  .ant-space, .ant-space-vertical {
    @include styles.mixins-style-flex-row;
  }

  .submit__wrap {
    @include styles.mixins-style-flex-row-space-between;
    float: right;
    padding-bottom: 5rem;
    margin-top: 34px;

    .secondary-button {
      margin-right: 16px;   
    }
  }
}