@use '../../../styles';

.ant-modal-wrap .ant-modal {
  @include styles.mixins-style-width-height(95% !important, 90vh);
  top: 5vh;
  max-width: 100%;
  padding: 0;

  &-content {
    padding: 0;
    @include styles.mixins-style-flex-center-column;
  }

  &:not(.modal__no-footer) {
    .ant-modal-body {
      height: calc(90vh - 220px);
    }
  }

  &.modal__no-footer {
    .ant-modal-body {
      height: calc(90vh - 113px);
    }
  }

  &.modal__header {
    width: 100%;
  }

  &-body {
    width: 100%;
    padding: 40px;
    overflow-y: auto;
  }

  &-close {
    color: styles.$variables-dark-blue-2;
    top: 30.5px;
    right: 40px;

    &-x {
      svg {
        @include styles.mixins-style-width-height(24px, 24px);
      }
    }
  }

  &-header {
    width: 100%;
    padding: 37px 40px;
    @include styles.mixins-style-border(none);
  }
}

.ant-modal-wrap {
  z-index: 1200;
}
