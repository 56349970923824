@use '../../../../styles';

.request-details-drawer {
  @include styles.mixins-style-width-height(100%, 100%);

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-top {
    &, .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane-active {
      height: 100%;
    }
  }

  .profile-overview__image {
    max-width: 100%;
  }
}
