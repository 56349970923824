@use "../../styles";

.info-track {
    @include styles.mixins-style-light-blue-card;
    display: flex;
    gap: 20px;

    &__text {
        text-align: left;
    }

    &__title {
        @include styles.mixins-style-font-format(18px, normal, bold, capitalize);
        margin-bottom: 0;
    }

    &__description {
        font-size: 16px;
    }

    svg {
        height: 30px;
        path {
            color: styles.$variables-dark-blue-2;
            
        }
    }
}
