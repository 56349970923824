@use '../../styles';

.edit-startup {
  @include styles.mixins-style-flex-column-start;
  margin-right: 20px;

  &__container {
    @include styles.mixins-style-width-height(100%, 100%);
    padding: 25px 0;
    margin-bottom: 25px;
    overflow-y: scroll;
  }

  &__fields {
    .primary-button {
      align-self: flex-end;
    }
  }

  &__section, &__fields {
    @include styles.mixins-style-flex-column-start;
    width: 100%;
    gap: 22px;
  }

  &__image-container {
    .startup_image {
      @include styles.mixins-style-width-height(fit-content, 200px);
    }

    .startup__no-image {
      @include styles.mixins-style-width-height(264px, 150px);
      @include styles.mixins-style-flex-center-row;
      background-color: styles.$variables-extended-light-blue;
      border-radius: 8px;

      svg {
        @include styles.mixins-style-width-height(32px, 32px);
        color: styles.$variables-dark-grey;
      }
    }
  }

  &__view-upload-button {
    &__container {
      @include styles.mixins-style-flex-row;
      gap: 8px;
    }

    &__rejected-document-text {
      margin: 0;
      color: styles.$variables-primary-error;
    }
  }
}
