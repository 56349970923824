@use '../../../../styles';

/* We used !important on the styles that are passed inline in the antd components,
since it's the only way to overwrite them */

.sidebar-container {
  background: transparent;
  padding: 0 15px;
  height: 100vh;
  @include styles.mixins-style-flex-column;
  align-items: center;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 0, (right));

  &__logo-container {
    padding-top: 35px!important;
    padding-bottom: 17px!important;
    text-align: left;
    height: 90px;
  }

  &__logo {
    height: 38px;
  }
}

.sidebar {
  border-radius: 16px 16px 0 0;
  background-color: styles.$variables-extended-light-blue;
  padding: 32px 0 0;
  height: calc(100vh - 90px);

  &__menu.ant-menu {
    border: none;
    @include styles.mixins-style-flex-column;
    background-color: styles.$variables-extended-light-blue;
    align-items: center;
    color: styles.$variables-dark-grey;
    font-weight: 600;
    font-size: 16px;
    height: calc(100vh - 90px);
    min-height: 600px;

    .ant-menu-item {
      height: 48px;
      padding: 6px!important;
      gap: 14px;
      margin: 0;

      &:not(:last-child) {
        margin: 0;
      }

      .ant-menu-title-content {
        @include styles.mixins-style-font-format(16px, 24px, 500, capitalize);
        width: 140px;
      }

      .sidebar-icon {
        @include styles.mixins-style-flex-center;
        @include styles.mixins-style-width-height(36px, 36px);
        padding: 6px;
        border-radius: 18px;

        svg {
          @include styles.mixins-style-width-height(24px, 24px);
        }
      }
    }

    .ant-menu-item-active {
      color: styles.$variables-extended-active-blue;
      border-radius: 8px;
    }

    .ant-menu-item:after {
      border-right: none;
    }

    .ant-menu-item-active:hover {
      background-color: styles.$variables-extended-active-blue;
      color: styles.$variables-dark-grey;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:focus {
    background-color: styles.$variables-extended-active-blue;
    color: styles.$variables-dark-grey;
    border-radius: 8px;
    align-items: center;

    .sidebar-icon {
      background-color: styles.$variables-primary-blue;
      color: styles.$variables-white;
    }

    .ant-menu-title-content {
      font-weight: 700;
    }
  }

  &__menu-trigger.ant-btn {
    padding: 0 24px 0 0;
    @include styles.mixins-style-width-height(100%, 24px);
    @include styles.mixins-style-flex-column-end-center;
    box-shadow: none;

    &, &:active, &:hover, &:focus {
      background: transparent;
      border: none;
    }

    svg {
      @include styles.mixins-style-width-height(24px, 24px);
      color: styles.$variables-dark-grey;
    }
  }
}

.sidebar-expanded {
  &.ant-layout-sider {
    background-color: styles.$variables-extended-light-blue;
    width: 230px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    flex: 0 0 230px !important;
  }

  .sidebar__menu.ant-menu {
    .ant-menu-item {
      width: 206px;
    }
  }
}

.sidebar-collapsed {
  &.ant-layout-sider {
    background-color: styles.$variables-extended-light-blue;
    width: 72px!important;
    max-width: 72px!important;
    min-width: 72px!important;
    flex: 0 0 72px!important;
  }

  .sidebar__menu.ant-menu {
    .ant-menu-item {
      width: 48px;
    }
  }
}
