@use '../../../styles';

.ant-notification {
  .hub71-notification {
    &.ant-notification-notice {
      border-radius: 8px;
      overflow: hidden;
      width: 493px;

      &.notification-without-description {
        .ant-notification-notice-with-icon {
          @include styles.mixins-style-flex-row;
          align-items: center;
          width: 100%;
        }

        .ant-notification-notice-description {
          display: none;
        }

        .notification-buttons {
          justify-content: flex-end;
        }
      }

      &.notification-with-description {
        .ant-notification-notice-description {
          margin: 16px 0 16px 27px;
        }

        .notification-buttons {
          justify-content: flex-start;
          margin-left: 27px;
        }
      }

      .ant-notification-notice-btn {
        width: 100%;
      }

      .ant-notification-notice-btn,
      .ant-notification-notice-description {
        margin: 0;
      }

      &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
      }

      .ant-notification-notice-icon {
        svg {
          @include styles.mixins-style-width-height(14px, 14px);
        }
      }

      .ant-notification-notice-message {
        @include styles.mixins-style-font-format(14px, 20px, 700);
        padding: 0;
        margin-left: 27px;
        margin-bottom: 0;
      }

      &-success {
        background: styles.$variables-secondary-success;

        .ant-notification-notice-icon,
        .ant-notification-notice-message,
        .ant-notification-notice-description {
          color: styles.$variables-primary-success;
        }

        &::before,
        .notification-primary-button {
          background: styles.$variables-primary-success;
        }
      }

      &-error {
        background: styles.$variables-secondary-error;

        .ant-notification-notice-icon,
        .ant-notification-notice-message,
        .ant-notification-notice-description {
          color: styles.$variables-primary-error;
        }

        &::before,
        .notification-primary-button {
          background: styles.$variables-primary-error;
        }
      }

      &-info {
        background: styles.$variables-secondary-info;

        .ant-notification-notice-icon,
        .ant-notification-notice-message,
        .ant-notification-notice-description {
          color: styles.$variables-primary-info;
        }

        &::before,
        .notification-primary-button {
          background: styles.$variables-primary-info;
        }
      }

      &-warning {
        background: styles.$variables-secondary-warning;

        .ant-notification-notice-icon,
        .ant-notification-notice-message,
        .ant-notification-notice-description {
          color: styles.$variables-primary-warning;
        }

        &::before,
        .notification-primary-button {
          background: styles.$variables-primary-warning;
        }
      }
    }
  }

  &-notice-close {
    display: none;
  }
}

.notification-buttons {
  @include styles.mixins-style-flex-row;
  gap: 16px;

  .notification-primary-button,
  .notification-secondary-button {
    height: 32px;
    @include styles.mixins-style-border(hidden, 0, transparent, 16px);
    padding: 4px 16px;
    @include styles.mixins-style-font-format(14px, 24px, 700, capitalize);
    @include styles.mixins-style-flex-center-row;
  }

  .notification-primary-button {
    color: styles.$variables-white;
  }

  .notification-secondary-button {
    color: styles.$variables-dark-grey;
  }
}
