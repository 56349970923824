@use '../../styles';

.get-in-touch {
  .submit__wrap {
    @include styles.mixins-style-flex-row-end;
    .secondary-button {
      margin-right: 16px;   
    }
  }
  .ant-space-vertical {
    flex-direction: row;
  }
}