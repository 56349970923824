@use '../../../../styles';


.ant-btn.show-more-btn {
    text-transform: uppercase;
}

.ant-btn-link.show-more-btn {
    font-weight: 600;
    color: styles.$variables-dark-blue-2;
    background-color: transparent;
    width: auto;

    &:hover,
    &:focus {
    color: styles.$variables-light-blue-6;
    }
}

.ant-btn.show-more-btn:before {
    background-color: transparent;
}
