@use '../../../../../../../../styles';

.request-item-card {
  @include styles.mixins-style-white-card;
  @include styles.mixins-style-flex-column;
  gap: 10px;

  &__title,
  &__sub-title,
  &__row__label,
  &__row__item {
    color: styles.$variables-dark-grey;
    margin-bottom: 0;
  }

  &__title {
    @include styles.mixins-style-font-format(18px, 24px, 700);
  }

  &__sub-title {
    @include styles.mixins-style-font-format(16px, 24px, 700);
  }

  &__row {
    @include styles.mixins-style-flex-row-v-center;
    gap: 4px;

    &__label, &__item {
      @include styles.mixins-style-font-format(12px, 16px, 300);

      &--bold {
        font-weight: 500;
      }
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    .ant-btn {
      flex: 1;
    }
  }

  .input {
    max-width: 100px;

    .ant-input-number {
      height: unset !important;
    }
  }
}
