@use '../../../../styles';

.details-overview {
  gap: 8px;
  color: styles.$variables-dark-grey;

  &__header {
    @include styles.mixins-style-font-format(18px, 24px, bold);
  }
  &__subheader {
    @include styles.mixins-style-font-format(16px, 24px, 400px, capitalize);
  }

  .sign::before{
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: 700;
  }
}
