@use '../../../../../styles';

.add-item {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;

  .ant-btn.ant-btn-icon-only {
    @include styles.mixins-style-width-height(20px, 20px);
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
    opacity: 0.7;
  }
}
