@use '../../../../styles';

.subcategories-overview {
  @include styles.mixins-style-flex-row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  &__filter-buttons {
    position: relative;
  }

  &__subcategories-overview-tabs {
    @include styles.mixins-style-flex-row;
    width: 85%;
    gap: 36px;

    .ant-btn.ant-btn-default {
      &.subcategories-overview-tabs--tab {
        @include styles.mixins-style-flex-center;
        @include styles.mixins-style-border(hidden, 0, transparent, 0, (top, right, left));
        box-shadow: none;
        background: transparent;
        padding: 12px 16px;
        height: 40px;
        flex: 1 1 100%;

        .subcategories-overview-tabs--tab-text {
          @include styles.mixins-style-capitalize-first-word-only;
          margin: 0;
        }

        &:not(.active-tab) {
          .subcategories-overview-tabs--tab-text {
            color: styles.$variables-dark-grey;
            @include styles.mixins-style-font-format(16px, 20px, 400);
          }
        }

        &.active-tab {
          @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 0, (bottom));

          .subcategories-overview-tabs--tab-text {
            color: styles.$variables-primary-blue;
            @include styles.mixins-style-font-format(16px, 20px, 700);
          }
        }
      }
    }
  }
}
