@use '../../styles';

.change-password__fields {
  @include styles.mixins-style-flex-column;
  width: 100%;
  gap: 22px;
  margin-top: 22px;

  .primary-button {
    align-self: flex-end;
  }
}
