.search-response-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  &__name {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__type {
    opacity: 0.6;
  }
}