@use '../../../../../styles';

.add-new-data-entry {

    .submit__wrap {
        @include styles.mixins-style-flex-row-end;
        margin-top: 8rem;
        
        .secondary-button {
          margin-right: 16px;   
        }
    }
}