@use '../../../../styles';

.welcome-header {
    display: flex;
    justify-content: space-between;

    &__title,
    &__description {
      text-align: left;
      color: styles.$variables-dark-blue-2;
    }

    &__title {
      @include styles.mixins-style-font-format(32px, normal, bold);
      margin-bottom: 0;
    }

    &__description {
      font-size: 16px;
    }

    &__startup-logo {
        object-fit: contain;
        height: 100px;
    }
}