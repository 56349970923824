@use '../../../../styles';

.range-option-form {
  @include styles.mixins-style-flex-center;
   margin-bottom: 12px;

  .delete-icon {
    cursor: pointer;
    margin-top: 30px;

    svg {
      fill: styles.$variables-primary-error;    
    }
  }

  .input {
    padding: 0 5px;
  }
}