@use '../../styles';

.events {
  height: 100%;
  gap: 32px;

  &,
  .ant-spin-nested-loading,
  .ant-spin-container,
  &__container {
    @include styles.mixins-style-flex-column;
    flex-grow: 1;
  }

  &__container {
    gap: 16px;
    margin-bottom: 24px;

    .no-events {
      @include styles.mixins-style-flex-center;
      flex: 1;
      margin: 0;
    }
  }
}
