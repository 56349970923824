@use '../../../../../styles';

.amount {
  @include styles.mixins-style-white-card;
  width: 100%;

  &.total {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);

    .amount__name, .amount__value {
      color: styles.$variables-primary-blue;
    }
  }

  &.consumed {
    @include styles.mixins-gradients-blue-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 16px);

    .amount__value, .amount__name {
      color: styles.$variables-extended-dark-blue;
    }
  }

  &.blocked {
    @include styles.mixins-gradients-red-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-red-stroke, 16px);

    .amount__value, .amount__name {
      color: styles.$variables-extended-red;
    }
  }

  &.balance {
    @include styles.mixins-gradients-green-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-green-stroke, 16px);

    .amount__value, .amount__name {
      color: styles.$variables-extended-green;
    }
  }

  &__container {
    @include styles.mixins-style-flex-column-start;
    align-items: center;
  }

  &__value, &__name {
    color: styles.$variables-dark-grey;
  }

  &__value {
    @include styles.mixins-style-font-format(18px, normal, bold);
    margin-bottom: 5px;
  }

  &__name {
    @include styles.mixins-style-font-format(12px, normal, normal, capitalize);
    margin-bottom: 0;
  }
}
