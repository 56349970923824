@use '../../../styles';

.collapsable-container {
  .ant-collapse-expand-icon {
    &, svg {
      @include styles.mixins-style-width-height(24px, 24px);
    }
  }

  .ant-collapse {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 8px);
    overflow: hidden;

    &-item {
      .ant-collapse-header-text {
        color: styles.$variables-dark-grey;
        @include styles.mixins-style-font-format(18px, normal, 700, capitalize);
        margin-left: 10px;
      }

      &:last-child {
        @include styles.mixins-style-border(hidden, 0, transparent, 15px);
      }
    }

    &-arrow {
      color: styles.$variables-dark-grey;
    }

    &.collapse {
      .ant-collapse-header {
        @include styles.mixins-style-flex-row-v-center;

        &-text {
          text-align: start;
        }
      }
    }

    .expandable {
      background-color: styles.$variables-extended-light-blue;

      .ant-collapse-content {
        &-box {
          padding: 0;
        }
      }
    }
  }
}
