@use '../../../../../styles';

.table-title-with-sorter {
  @include styles.mixins-style-flex-row;
  gap: 12px;

  &--icon {
    color: styles.$variables-primary-blue;
  }
}
