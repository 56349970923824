@use '../../../../../styles';

.overview-data-column {
  @include styles.mixins-style-flex-column;
  flex: 1 1 0;
  text-align: start;

  .overview-data-label, .overview-data-text {
    color: styles.$variables-dark-grey;
  }

  .overview-data-label {
    @include styles.mixins-style-font-format(12px, normal, normal, capitalize);
  }

  .overview-data-text {
    @include styles.mixins-style-font-format(14px, normal, bold, capitalize);
  }

  &.overview-data-column__invert {
    @include styles.mixins-style-flex-reverse-column;

    .overview-data-text {
      margin-bottom: 5px;
    }

    .overview-data-label {
      margin: 0;
    }
  }

  &:not(&.overview-data-column__invert) {
    @include styles.mixins-style-flex-column;

    .overview-data-text {
      margin: 0;
    }

    .overview-data-label {
      margin-bottom: 5px;
    }
  }
}