@use '../../styles';

.request-details {
  width: 100%;

  .overview-data {
    &-column {
      flex: 1 1 50%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-text {
      font-weight: normal;
    }
  }

  .overview-data-label, .ant-form-item-label label {
    @include styles.mixins-style-font-format(12px, normal, bold, uppercase);
    opacity: 0.8;
  }

  &__row {
    @include styles.mixins-style-flex-row;
    flex-wrap: wrap;
  }
}
