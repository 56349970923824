@use '../../styles';

.startup-logo {
  display: block;
  @include styles.mixins-style-width-height(100%, 100%);
  -o-object-fit: contain;
  object-fit: contain;
}

.cell {
  @include styles.mixins-gradients-blue-gradient;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 30px);
  @include styles.mixins-style-text-truncate;
  color: styles.$variables-dark-blue-1;
  padding: 5px 9px;
  max-width: 200px;
  width: fit-content;
  text-align: center;
}

.startups-overview {
  overflow: hidden;

  &-column {
    @include styles.mixins-style-flex-row-v-center;
    gap: 16px;

    &.ant-avatar {
      @include styles.mixins-style-width-height(30px, 30px);

      img {
        object-fit: contain;
      }
    }

    .overview-column-name {
      width: calc(100% - 46px);
    }
  }
}
