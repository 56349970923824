@use '../../../../../../../styles';

.custom-slider-range {
  .custom-slider-handle {
    margin: -25px 0 0 -20px;
    position: absolute;
    width: 48px;
    padding: 0 0.25rem;
    cursor: pointer;
    border-radius: 16px;
    background: styles.$variables-light-blue-3;
    color: styles.$variables-dark-blue-2;
    text-align: center;
  }
}
