@use '../../../../styles';

.event-filters {
  &__buttons {
    @include styles.mixins-style-flex-row-end;
    gap: 16px;
    padding-bottom: 40px;
  }

  .ant-checkbox-group-item {
    @include styles.mixins-style-capitalize-first-word-only;
  }
}
