@use '../../../../styles';

.startup-details {
  &__cohort-details {
    width: 100%;
    
    &__info-row:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
