@use '../../styles';

.edit-people {
  &__container {
    @include styles.mixins-style-width-height(100%, 100%);
    padding: 25px 0;
    margin-bottom: 25px;
  }

  &__fields {
    @include styles.mixins-style-flex-column-start;
    gap: 22px;

    .error {
      @include styles.mixins-style-font-format(14px, 22px, 500);
      color: styles.$variables-primary-error;
    }

    .primary-button {
      align-self: flex-end;
      margin-bottom: 50px;
    }
  }
}
