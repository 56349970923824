@use '../../../styles';

.service-card-container {
  margin-bottom: 20px;

  .service-card {
    border-radius: 16px;
    overflow: hidden;

    .service-inactive {
      opacity: 50%;
    }
  }

  &__title-container {
    @include styles.mixins-style-flex-row-space-between;
    gap: 8px;

    .title {
      @include styles.mixins-style-font-format(22px, 28px, 900, capitalize);
      @include styles.mixins-style-text-truncate;
      color: styles.$variables-dark-grey;
      width: 80%;
      max-height: 80px;
      text-align: left;
    }

    .no-image-title {
      width: 100%;
    }

    .card-image {
      @include styles.mixins-style-width-height(50px, 50px);
      object-fit: contain;
      padding: 4px;
      gap: 4px;
      border-radius: 8px;
    }

    .empty-image {
      @include styles.mixins-style-width-height(70px, 50px);
      @include styles.mixins-style-flex-center;
      background-color: styles.$variables-extended-light-blue;
    }
  }

  &__image-container {
    @include styles.mixins-style-width-height(100%, 100%);
  }

  .ant-card {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
    background: styles.$variables-white;
    box-shadow: styles.$variables-vertical-box-shadow;
    margin-bottom: 20px;
    min-height: 420px;

    .ant-card-body {
      @include styles.mixins-style-flex-column;
      padding: 16px;
      min-height: 420px;

      .subtitle {
        @include styles.mixins-style-font-format(14px, 20px, 700);
        @include styles.mixins-style-text-truncate;
        color: styles.$variables-dark-grey;
      }

      .description-container {
        .description-title {
          @include styles.mixins-style-font-format(14px, 28px, bold, capitalize);
          @include styles.mixins-style-text-truncate;
          color: styles.$variables-dark-grey;
          width: 80%;
          max-height: 80px;
          text-align: left;
        }
      }
    }

    .buttons-section {
      @include styles.mixins-style-flex-row-space-between;
      margin-top: auto;
      gap: 8px;

      .secondary-delete-button,
      .secondary-button {
        width: 50%;
      }

      &.single-button-section {
        .secondary-button {
          width: 100%;
        }
      }

      .ant-btn.primary-button.md, .ant-btn.secondary-delete-button.md {
        width: calc(50% - 8px);
      }

      .ant-btn.primary-button.md.requested-service-button:disabled {
        background: styles.$variables-dark-blue;
        border-color: styles.$variables-dark-blue;
        color: styles.$variables-white;
      }

      .ant-btn.primary-button.md:disabled,
      .ant-btn.secondary-button.md:disabled {
        background: styles.$variables-secondary-grey;
        border-color: styles.$variables-primary-grey;
        color: styles.$variables-primary-grey;
      }
    }

    .description-container {
      flex: 3;
      min-height: 120px;
    }

    .category-container {
      @include styles.mixins-style-flex-column-start;
      text-align: left;
      flex: 0.5;

      .description-title {
        @include styles.mixins-style-font-format(14px, 20px, 700, capitalize);
        color: styles.$variables-dark-grey;
      }

      :not(.rounded) {
        .category-section {
          .description {
            @include styles.mixins-style-font-format(14px, 20px, 400);
            color: styles.$variables-dark-grey;
          }
        }
      }
    }

    .category-container {
      .rounded {
        color: styles.$variables-dark-blue-1;

        .category-section {
          @include styles.mixins-style-flex-row;
          width: 100%;
          flex: 1;
          gap: 8px;
        }
      }
    }
  }
}
