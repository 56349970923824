@use '../../../../../styles';

.form-builder__row .input:has(.add-button-container) {
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.input {
  .add-button-container {
    width: 100%;

    .input,
    .ant-row .ant-form-item-row {
      width: 100%;
      max-width: 100%;

      .ant-form-item-explain.ant-form-item-explain-connected {
        height: 44px;
      }
    }


    &__label {
      color: styles.$variables-dark-grey;
      display: block;
      margin-bottom: 2px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .dropdown-button.ant-dropdown-button {
      @include styles.mixins-style-border(hidden, 0, transparent, 4px);
      @include styles.mixins-style-width-height(100%, auto);
      min-height: 48px;
      background: transparent;
      color: styles.$variables-dark-blue-2;
      border-radius: 0;
      padding-bottom: 5px;
      width: 100%;
    }

    .input {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
    .ant-select-selector {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid styles.$variables-light-blue-4;
    }

    .ant-select-multiple .ant-select-selection-item {
      background: linear-gradient(180deg, rgba(234, 242, 254, 0.4) 0%, #EAF2FE 70.31%), #FFFFFF;
      border: 1px solid #CED9FF;
      padding: 15px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      color: styles.$variables-extended-dark-blue;

      &:hover {
        color: styles.$variables-extended-dark-blue;
      }
    }

    .ant-btn-link {
      color: styles.$variables-dark-blue-2;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0;

      &:hover {
        color: styles.$variables-light-blue-6;
      }

      svg {
        margin-right: 10px;
        height: -10px;
      }
    }
  }

}
