@use '../../styles';

.add-member__select-startup-option {
  display: flex;
  gap: 10px;
  color: styles.$variables-primary-blue;
}

.add-new-member-form {
  .add-button-container {
    .ant-btn-link {
      width: auto;
    }
  }
}

.add-member__filter-table-title {
  @include styles.mixins-style-flex-column-start-center;

  .ant-btn-link {
    @include styles.mixins-style-flex-row-v-center;
    padding: 0;
    gap: 5px;

    &:hover {
      color: styles.$variables-extended-dark-blue;
    }
  }

  .add-member__add-startup {
    @include styles.mixins-style-font-format(12px, normal, bold, uppercase);

    svg {
      height: -10px;
    }
  }

  &-text {
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(25px, normal, bold);
    margin-bottom: 0;
  }

  &-body {
    @include styles.mixins-style-flex-row-v-center;
    gap: 20px;
  }
}
