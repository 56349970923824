@use '../../../../../../styles';

.pricing-option-form {
  margin-bottom: 8px;

  .field-error {
    @include styles.mixins-style-font-format(14px, 20px, 600);
    color: styles.$variables-primary-error;
    margin: 0;
  }
  
  .delete-icon {
    cursor: pointer;
    padding-top: 15px;

    svg {
      fill: styles.$variables-primary-error;    
    }
  }
}
