@use '../../../../../styles';

.overview-column {
  @include styles.mixins-style-flex-column;
  gap: 8px;

  &__label,
  &__value,
  .ant-form-item-label .ant-form-item-required {
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(18px, 24px);
  }

  &__value {
    font-weight: 400;

    .attributes {
      background: transparent;
      margin: 0;
      padding: 0;
      border: none;
      box-shadow: none;
    }

    .ant-select.ant-select-single {
      width: 100%;
    }
  }

  &__label,
  .ant-form-item-label .ant-form-item-required {
    font-weight: 700;
  }

  .ant-form-item-label .ant-form-item-required {
    margin-bottom: 8px;
  }
.sign::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;    
    font-weight: 700;
  }
}
