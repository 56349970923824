@use '../../../../../../styles';

.requested-service{
  margin-bottom: 20px;
  padding: 20px;

  .request-service {
    &__container {
      @include styles.mixins-style-flex-row-space-between;
      @include styles.mixins-style-border(solid, 1px, transparent, 8px);
      width: 100%;
      gap: 20px;
      background-color: styles.$variables-white;
      padding: 10px;
    }

    &__details {
      flex: 1 1 0;
    }

    &__details {
      color: styles.$variables-dark-blue-2;
      width: 50%;

      &__name, &__description {
        color: styles.$variables-dark-blue-2;
        margin: 0;
      }

      &__name {
        @include styles.mixins-style-font-format(16px, normal, bold);
        margin-bottom: 10px;
      }

      &__description {
        @include styles.mixins-style-font-format(12px, normal, normal);
        @include styles.mixins-style-text-truncate;
        width: 80%;
      }
    }
  }
}
