@use '../../../../styles';

.performance-details {

  &__report {
    @include styles.mixins-style-font-format(28px, 32px, 900);
    color:  styles.$variables-dark-grey;
    margin-bottom: 24px;
  }

  .overview-column {
    margin-bottom: 24px;
  }
}