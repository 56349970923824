@use "../../styles";

.submit-overview {
  @include styles.mixins-style-flex-column;
  max-width: 80%;
  gap: 20px;

  &__welcome {
    display: flex;
    justify-content: space-between;

    &-title,
    &-description {
      text-align: left;
      color: styles.$variables-dark-blue-2;
    }

    &-title {
      @include styles.mixins-style-font-format(32px, normal, bold);
      margin-bottom: 0;
    }

    &-description {
      font-size: 16px;
    }
  }

  &__wallet {
    @include styles.mixins-style-light-blue-card;
    padding: 0;

    .ant-alert-info {
      background-color: styles.$variables-light-blue-3;
      border: none;
      
      .ant-alert-message {
        color: styles.$variables-dark-blue-2;
        text-align: left;
      }

      path {
        color: styles.$variables-dark-blue-2;
      }
    }
  }

  &__wallet-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      font-weight: bold;
    }
  }

  &__wallet-body {
    padding: 20px;
    display: flex;
    gap: 100px;
    flex-wrap: wrap;
  }

  .wallet-item:first-child {
    .wallet-item__amount {
      font-size: 32px;
    }
  }

  &__startup-logo {
    object-fit: contain;
    height: 100px;
  }

  &__stat-cards {
    display: flex;
    gap: 10px;
  }
}
