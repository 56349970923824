@use '../../styles';
@use 'sass:map';

.reset-page {
  @include styles.mixins-style-flex-center-column;
  @include styles.mixins-style-width-height(100vw,100vh);

  .reset-container {
    @include styles.mixins-style-flex-center-column;
    @include styles.mixins-style-width-height(100%,100%);
    max-width: 600px;
    max-height: 480px;
    background: styles.$variables-light-blue-2;
    border-radius: 8px;

    .sub-form {
      @include styles.mixins-style-flex-column;
      align-items: center;
      width: 100%;
      padding: 0 60px;

      .heading {
       @include styles.mixins-style-font-format(32px, normal, 700, capitalize);
        margin: 0 0 2rem;
        color: styles.$variables-dark-blue-2;
        width: 100%;
        text-align: left;
      }

      .ant-form-item-label label {
        color: styles.$variables-dark-blue-2;
        font-size: 12px;
      }

      .input {
        .ant-form-item-control-input-content {
          &:focus {
            border: 1px solid styles.$variables-dark-blue-2;
          }

          &:-webkit-autofill {
            &, &:focus {
              // Needed to override internal-autofill-selected styling
              -webkit-box-shadow: 0 0 0 1000px styles.$variables-light-blue-3 inset;
              box-shadow: 0 0 0 1000px styles.$variables-light-blue-3 inset;
            }
          }
        }
      }

      .input-heading {
          margin: 0 0 0.5rem;
          width: 100%;
          text-align: left;
          color: styles.$variables-dark-blue-1;
        }

      .reset-page__buttons {
        @include styles.mixins-style-flex-center;
        gap: 20px;
        width: 100%;
        margin-top: 20px;

        .button {
          width: 100%;

          .ant-btn {
            width: 100%;
          }
        }
      }
    }
  }

  .reset__send-email {
    margin-bottom: 40px;
    width: 100%;
  }
}
