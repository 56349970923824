@use '../../../../styles';

.incentive-agreement {
  &-details-drawer {
    @include styles.mixins-style-flex-column;
    gap: 8px;
  }

  &-upload-drawer {
    .ant-form.ant-form-horizontal {
      @include styles.mixins-style-flex-column;
      column-gap: 16px;
      width: 100%;

      .input .dragger.ant-upload.ant-upload-drag {
        width: 100%;
      }

      .input .form-item.ant-form-item:not(.form-item-vertical) .ant-col.ant-form-item-label {
        width: 35%;
      }

      .input .form-item.ant-form-item:not(.form-item-vertical) .ant-col.ant-form-item-control {
        width: 65%;
      }
    }

    .message {
      @include styles.mixins-style-font-format(16px, 22px, 500);
      color: styles.$variables-dark-grey;
      margin: 0;
    }

    .submit-button {
      @include styles.mixins-style-flex-row-end;
      width: 100%;

      .ant-btn.primary-button {
        width: 120px;
      }
    }
  }
}
