@use '../../../../styles';

.service-offered-file {
  @include styles.mixins-style-flex-row-space-between;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 8px);
  padding: 10px;
  flex: auto;

  &__info {
    @include styles.mixins-style-flex-row;
    @include styles.mixins-style-width-height(50%, auto);
    align-items: center;
    flex: 1;

    &--container {
      @include styles.mixins-style-flex-column;
      justify-content: flex-start;
    }

    &--name {
      text-align: start;
      @include styles.mixins-style-font-format(14px, 20px, 400, capitalize);
      color: styles.$variables-dark-grey;
      margin-bottom: 5px;
    }
  }

  &__button {
    @include styles.mixins-style-flex-column;

    .ant-btn.ant-btn-text.ant-btn-icon-only.view-btn {
      background: none;
    }
  }
}
