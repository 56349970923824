@use '../../styles';

.ant-form.new-service-container {
  .ant-form-item.form-item.form-item-vertical {
    margin-bottom: 15px;
  }

  .input {
    flex-basis: 100%;
  }

  .ant-space, .ant-space-vertical {
    @include styles.mixins-style-flex-row;
  }
}