@use '../../../../../styles';

.tag-select {
    display: flex !important;
    gap: 10px;

    .ant-radio-button-wrapper {
      @include styles.mixins-gradients-blue-gradient;
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 20px);
      color: styles.$variables-extended-dark-blue;
      box-shadow: none;
      min-width: 100px;
      text-align: center;


      &:first-child, &:last-child {
          border-radius: 20px;
      }

      &:hover {
        color: styles.$variables-extended-dark-blue;
      }
    }

    .ant-radio-button-wrapper:not(:first-child):before {
        display: none;
    }
}

    .ant-radio-group-solid.tag-select .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: styles.$variables-dark-blue-2;
        box-shadow: none;

      &:hover {
        background-color: styles.$variables-dark-blue-1;
        color: styles.$variables-white;
      }
    }
