@use '../../../../../../styles';

.overview-tab-content {
  @include styles.mixins-style-flex-column;
  justify-content: space-between;
  height: 100%;

  &--container {
    @include styles.mixins-style-flex-column;
    gap: 25px;
  }

  .video-url {
    @include styles.mixins-style-font-format(18px, 24px, 400);
    color: styles.$variables-primary-blue;
    text-decoration-line: underline;
  }

  .overview-tab {
    &__total {
      @include styles.mixins-style-flex-row-space-between;
      border-top: 1px solid styles.$variables-tertiary-grey; 
      padding: 16px 0 0;
    }

    &__total-text,
    &__total-amount {
      @include styles.mixins-style-font-format(18px, 24px, 700);
    }
    &__request-sender-recipient-text {
      margin-bottom: 0.5rem;
      p {
        font-weight: 300;
        margin-bottom: 0rem;
      }
      h4 {
        margin-bottom: 0rem;
      }
    }

    &__actions {
      margin-top: 20px;
      @include styles.mixins-style-flex-row-end;
      gap: 10px;

      .form-standalone {
        flex: 1;

        .ghost-button {
          border-color: styles.$variables-primary-error;
          color: styles.$variables-primary-error;

          &:hover {
            background-color: transparent;
            border-color: styles.$variables-primary-error;
          }
        }
      }
    }
  }
}
