@use '../../styles';

.add-startup-drawer {
  .title {
    @include styles.mixins-style-flex-start;
    @include styles.mixins-style-font-format(14px, normal, bold, capitalize);
    color: styles.$variables-dark-grey;
    margin-bottom: 20px;
  }

  &__activation-link-title {
    @include styles.mixins-style-font-format(20px, normal, bold);
    margin-bottom: -5px;
  }
}
