@use '../../../../../../../styles';

.uploaded-file {
  .button-container {
    @include styles.mixins-style-flex-column;
    padding: 10px;
    width: auto;

    .ant-upload {
      width: 100%;
    }

    .ant-btn.change-button, .ant-btn.preview-button {
      background-color: styles.$variables-light-blue-2;
      color: styles.$variables-dark-blue-2;
      @include styles.mixins-style-font-format(12px, 12px, bold, uppercase);
      @include styles.mixins-style-border(hidden, 0, transparent, 50px);
      box-shadow: none;
      width: 100%;
      padding: 8px 30px;
    }

    .ant-btn.preview-button {
      margin-bottom: 10px;
    }

    .ant-btn-dangerous {
      border: none;
      margin-top: 8px;
    }
  }
}
