@use '../../../../styles';

.program-details {
  &__modal-title {
    text-align: start;
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-flex-start;
    margin-bottom: 0;
  }

  &__expandable_table {
    margin-bottom: 16px;
  }

  &__about-overview {
    margin-bottom: 16px;

    .overview__title {
      color: styles.$variables-dark-grey;
    }

    .overview {
      background-color: styles.$variables-white;

      &__card {
        @include styles.mixins-style-flex-row-space-between;

        .overview-data-column {
          .overview-data-label, .overview-data-text {
            color: styles.$variables-dark-grey;
          }
        }
      }
    }
  }

  &__about-description {
    margin: 0;
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(12px, normal, normal);
  }
}

.ant-modal-wrap .ant-modal {
  width: 60% !important;

  .overview {
    &-data {
      text-align: start;

      .label {
        font-size: 14px;
        color: styles.$variables-dark-grey;
        margin-bottom: 0;
      }

      .overview-data-text {
        color: styles.$variables-dark-grey;
        @include styles.mixins-style-font-format(14px, normal, 600, capitalize);
      }
    }

    &__subtitle {
      @include styles.mixins-style-font-format(16px, normal, 500, capitalize);
      color: styles.$variables-dark-grey;
      margin: 20px 0 15px;
    }
  }
}

.ant-modal-header .ant-modal-title {
  .title-with-actions {
    gap: 0 !important;
    margin-bottom: 0 !important;
  }
}
