@use '../../styles';

.member-details {
  @include styles.mixins-style-flex-column;
  @include styles.mixins-style-width-height(100%, auto);
  padding: 10px;
  gap: 16px;

  &__row-container {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-row-space-between;
    align-items: flex-start;
    @include styles.mixins-style-white-card;
  }

  &__overview {
    text-align: left;
  }
}

.profile-details {
  @include styles.mixins-style-flex-column;
  gap: 16px;
}
