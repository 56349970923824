@use "../../../../../../../../styles";

.add-people-table {
  width: 50vw;

  .ant-table-tbody {
    tr td {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-1, 0, (bottom));
      background: styles.$variables-white;
    }
  }

  .ant-table-thead {
    tr th {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-1, 0, (bottom));
      background: styles.$variables-white;
    }
  }

  tr, th {
    display: flex;

    .ant-table-cell {
      flex: 1;
    }

    .ant-table-cell.ant-table-selection-column {
      order: 2;
      flex: 0.1;
    }
  }


  th.ant-table-cell.ant-table-selection-column {
    .ant-table-selection {
      margin: 0 auto;
    }
  }
  

  .ant-table-content {
    overflow-y: scroll;
    max-height: 70vh;
  }

  .input {
    margin-bottom: 20px;

    .ant-input::placeholder {
      color: styles.$variables-dark-blue-1;
    }
  }

  .ant-table-column-title {
    color: styles.$variables-dark-blue-2;
  }

  .ant-table-cell {
    color: styles.$variables-dark-blue-2;
    background-color: styles.$variables-white;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: styles.$variables-light-blue-1;
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: styles.$variables-light-blue-2;
  }

  .ant-checkbox-inner {
    &, .ant-checkbox-checked {
        border-radius: 20px;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: styles.$variables-dark-blue-2;
      border-color: styles.$variables-dark-blue-2;
    }

  } 

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: styles.$variables-dark-blue-2;
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    .ant-btn {
      flex: 1;
      min-height: 40px;
    }
  }
}
