@use '../../../../../styles';

.select-tags-container {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .dropdown-button.ant-dropdown-button {
    @include styles.mixins-style-width-height(100%, 40px);
    background: transparent;
    color: styles.$variables-dark-blue-2;
    @include styles.mixins-style-border(none);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke , 0, (bottom));
  }

  .ant-select-multiple .ant-select-selection-item {
    @include styles.mixins-gradients-blue-gradient;
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 20px);
    @include styles.mixins-style-flex-center;
    color: styles.$variables-extended-dark-blue;
    padding: 6px 12px;

    &:hover {
      color: styles.$variables-extended-dark-blue;
    }
  }

  .ant-btn-link {
    @include styles.mixins-style-font-format(14px, 22px, 600, uppercase);
    color: styles.$variables-dark-blue-2;
    padding: 0;

    &:hover {
      color: styles.$variables-light-blue-6;
    }

    svg {
      margin-right: 10px;
    }
  }

  .ant-select-selection-item-remove {

    svg {
      fill: styles.$variables-extended-dark-blue;
    }
  }
}
