@use '../../styles';

.article-container {
    --border-black-light: #00000030;
    --color-grey-light: #00000080;
    padding: 2rem;

    h1.article-title {
        margin-bottom: 0.5rem;
    }

    .article-meta-details {
        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: flex-start;
    
            li {
                position: relative;
                color: var(--color-grey-light);
                font-size: clamp(0.9375rem, 0.9228rem + 0.0643vw, 1rem);
    
                &:not(:last-child):after {
                    content: '';
                    position: absolute;
                    right: -0.5rem;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: var(--border-black-light);
                }
            }
        }
    }

    .article-featured-image {
        margin: 2rem auto;
        img {
            height: clamp(37.5rem, 36.033rem + 6.4309vw, 43.75rem);
            object-fit: cover;
        }
    }

    p,
    ol li {
        margin-top: 1rem;
        font-size: clamp(0.875rem, 0.8603rem + 0.0643vw, 0.9375rem);
    }
}

