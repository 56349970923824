@use '../../../../styles';

.static-notification {
  @include styles.mixins-style-border(hidden, 0, transparent, 8px);
  @include styles.mixins-style-width-height(100%, 48px);
  min-height: 48px;
  @include styles.mixins-style-flex-row-space-between;
  overflow: hidden;

  .notification-buttons {
    @include styles.mixins-style-flex-row-v-center;
    gap: 8px;
    margin-right: 16px;
  }

  &__container {
    @include styles.mixins-style-flex-row-v-center;
    gap: 21px;
    height: 100%;
  }

  &__message-container {
    @include styles.mixins-style-flex-row-v-center;
    padding: 14px 0;
    gap: 9px;
  }

  &__message {
    @include styles.mixins-style-font-format(14px, 20px, 700);
  }

  &__description {
    @include styles.mixins-style-font-format(14px, 20px, 400);
  }

  &__message-container-right {
    @include styles.mixins-style-flex-column;
    justify-content: center;
  }

  &__border {
    @include styles.mixins-style-width-height(4px, 100%);
  }

  &--with-description {
    height: 88px;
    margin-top: 16px;

    .static-notification__message-container {
      align-items: flex-start;

      .static-notification__message {
        font-weight: bold;
        margin-bottom: 10px;
      }

      svg {
        margin-top: 3px;
      }
    }
  }

  &.notification {
    &-error {
      background: styles.$variables-secondary-error;

      .static-notification__border,
      .notification-primary-button {
        background: styles.$variables-primary-error;
        background: transparentize(styles.$variables-primary-error, 0.15);

        @include styles.mixins-style-hover {
          color: styles.$variables-white;
        }
      }

      .notification-secondary-button {
        @include styles.mixins-style-hover {
          color: styles.$variables-primary-error;
        }
      }

      svg {
        fill: styles.$variables-primary-error;
      }

      .static-notification__message,
      .static-notification__description {
        color: styles.$variables-primary-error;
      }
    }

    &-success {
      background: styles.$variables-secondary-success;

      .static-notification__border,
      .notification-primary-button {
        background: styles.$variables-primary-success;
        background: transparentize(styles.$variables-primary-success, 0.15);

        @include styles.mixins-style-hover {
          color: styles.$variables-white;
        }
      }

      .notification-secondary-button {
        @include styles.mixins-style-hover {
          color: styles.$variables-primary-success;
        }
      }

      svg {
        fill: styles.$variables-primary-success;
      }

      .static-notification__message,
      .static-notification__description {
        color: styles.$variables-primary-success;
      }
    }

    &-info {
      background: styles.$variables-secondary-info;

      .static-notification__border,
      .notification-primary-button {
        background: styles.$variables-primary-info;

        @include styles.mixins-style-hover {
          color: styles.$variables-white;
          background: transparentize(styles.$variables-primary-info, 0.15);
        }
      }

      .notification-secondary-button {
        @include styles.mixins-style-hover {
          color: styles.$variables-primary-info;
        }
      }

      svg {
        fill: styles.$variables-primary-info;
      }

      .static-notification__message,
      .static-notification__description {
        color: styles.$variables-primary-info;
      }
    }

    &-warning {
      background: styles.$variables-secondary-warning;

      .static-notification__border,
      .notification-primary-button {
        background: styles.$variables-primary-warning;

        @include styles.mixins-style-hover {
          color: styles.$variables-white;
          background: transparentize(styles.$variables-primary-warning, 0.15);
        }
      }

      .notification-secondary-button {
        @include styles.mixins-style-hover {
          color: styles.$variables-primary-warning;
        }
      }

      svg {
        fill: styles.$variables-primary-warning;
      }

      .static-notification__message,
      .static-notification__description {
        color: styles.$variables-primary-warning;
      }
    }
  }
}
