@use '../../styles';

.startup-details {
  padding-bottom: 20px;

  .stat-cards-container {
    gap: 20px;
  }

  @include styles.mixins-style-flex-column;
  @include styles.mixins-style-width-height(100%, auto);
  gap: 16px;

  &__row-container {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-row-space-between;
    @include styles.mixins-style-white-card;
    align-items: flex-start;
  }
  .about-container {
    &__title-section {
      @include styles.mixins-style-font-format(18px, normal, bold, capitalize);
      margin: 16px 0;
      color: styles.$variables-dark-grey;
    }
  }

  .service-providers {
    &-column {
      @include styles.mixins-style-flex-row-v-center;
      gap: 16px;

      &.ant-avatar {
        @include styles.mixins-style-width-height(30px, 30px);

        img {
          object-fit: contain;
        }
      }

      .overview-column-name {
        width: calc(100% - 46px);
      }
    }
  }
}
