@use '../../../../../../styles';

.cohort-details__modal {
  @include styles.mixins-style-flex-column;
  gap: 16px;

  .overview__card-data .ant-col {
    width: 50%;
  }
}
