@use '../../../styles';

.button-with-prompt {
  &__actions {
    @include styles.mixins-style-flex-row-end;
    gap: 10px;

    .ant-btn.ant-btn-default.ghost-button {
      text-transform: capitalize;
    }
  }

  .input {
    label {
      font-weight: bold;
      text-transform: none;
    }
    .ant-form-item .form-item .form-item-vertical {
      margin-bottom: 0;
    }
  }
}
