@use '../../../../../../../../styles';

.request-comment {
    @include styles.mixins-style-white-card;
    border: 1px solid styles.$variables-tertiary-grey;
    display: flex;
    gap: 10px;

    &__date,
    &__startup-name,
    &__name {
        margin-bottom: 5px;
    }

    &__container {
        flex: 1;
    }

    &__avatar {
        min-width: 24px;
        max-width: 24px;
        max-height: 24px;
    }

    &__name {
        font-weight: 700;
    }

    &__startup-name, &__date {
        color: styles.$variables-primary-grey !important;
    }

    &__user-info {
        display: flex;
        gap: 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__text {
        word-break: normal;
    }
}