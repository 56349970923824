@use '../../../../styles';

.settings-table {
  margin-top: 20px;
  box-shadow: styles.$variables-vertical-box-shadow;

  .table {
    gap: 0;
  }

  .ant-table {
    border-radius: 8px;
    overflow: hidden;
  }

    tr, th {
      display: flex;

      .ant-table-cell {
        @include styles.mixins-style-flex-row-v-center;
        flex: 1;
      }

      .ant-table-cell.ant-table-selection-column {
        order: 2;
        flex: 0.1;

        .ant-checkbox-wrapper {
          margin: 0 auto;
        }
      }
    }

    th.ant-table-cell.ant-table-selection-column {
      .ant-table-selection {
        margin: 0 auto;
      }
    }


    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: styles.$variables-dark-blue-2;
        border-color: styles.$variables-dark-grey;
      }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      background-color: styles.$variables-dark-blue-2;
    }

    .table-column {
        &__description {
          @include styles.mixins-style-text-truncate;
            max-width: 150px;
            margin: 0;
        }
    }
}
