@use '../../../styles';

.text {
  @include styles.mixins-style-capitalize-first-word-only;
  margin: 0;
  color: styles.$variables-dark-grey;

  &-xxs {
    @include styles.mixins-style-font-format(14px, 24px, 700);
  }

  &-xs {
    @include styles.mixins-style-font-format(16px, 24px, 700);
  }

  &-s {
    @include styles.mixins-style-font-format(18px, 24px, 700);
  }

  &-m {
    @include styles.mixins-style-font-format(22px, 28px, 700);
  }

  &-l {
    @include styles.mixins-style-font-format(31px, 40px, 700);
  }

  &-200 { font-weight: 200 }

  &-300 { font-weight: 300 }

  &-400 { font-weight: 400 }

  &-500 { font-weight: 500 }

  &-600 { font-weight: 600 }

  &-800 { font-weight: 700 }

  &-900 { font-weight: 900 }
}
