@use '../../../../../styles';

.form-header {
    @include styles.mixins-style-flex-row;
    align-items: center;
    margin-top: 20px;

    .delete-icon {
        padding-top: 5px;
        cursor: pointer;
        svg {
            @include styles.mixins-style-width-height(50px, 20px);
            fill: styles.$variables-red;    
        }
    }
}

.contact__checkbox {
    margin-top: 10px;              
    
    span {
        @include styles.mixins-style-font-format(12px, normal, 700);
        color: styles.$variables-dark-blue-1;
    }
}