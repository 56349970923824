@use '../../../../../../../../styles';

.overviews__left-content {
  @include styles.mixins-style-flex-column;
  gap: 16px;

  .wallet-overview-summary {
    .overview .overview__card {
      flex-direction: row !important;
    }
  }
}
