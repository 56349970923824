@use '../../../styles';

.form-builder,
.form-standalone {

  @include styles.mixins-style-flex-column;
  gap: 0 24px;

  .ant-btn-link {
    min-height: unset;
    width: unset;
    margin-top: 0;
  }

  .heading {
    color: styles.$variables-dark-grey;
  }
}

.form-builder {
  .heading {
    margin-bottom: 20px;
  }

  &__row-title {
    @include styles.mixins-style-font-format(18px, normal, bold, capitalize);
    color: styles.$variables-dark-grey;
  }

  &__titles {
    @include styles.mixins-style-flex-row-space-between;
    gap: 20px;
  }

  &__field-title {
    margin-bottom: 0;
    flex-basis: 48%;
    @include styles.mixins-style-font-format(14px, normal, bold);
    color: styles.$variables-dark-grey;

    @media (max-width: 1000px) {
      margin-bottom: -20px;
      align-self: flex-start;
    }
  }

  &__row {
    @include styles.mixins-style-flex-row-space-between;
    align-items: flex-end;
    gap: 20px;

    .input {
      flex-basis: 48%;
      max-width: 48%;

      &:has(textarea) {
        flex-basis: unset;
        max-width: 100%;
      }

      .dragger.ant-upload.ant-upload-drag {
        width: 100%;
      }

      .ant-form-item {
        margin: 0;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      .input {
        max-width: 100%;
      }
    }
  }

  &__row--one-column {
    @include styles.mixins-style-flex-column;
    gap: 20px;
    margin-bottom: 20px;

    .input {
      max-width: 100%;
      
      .ant-upload {
        width: 100% !important;
      }
    }
  }
}