@use '../../../../../../../../styles';

.add-more-services__service-card {

  &--container {
    .text.text-m {
      @include styles.mixins-style-text-truncate;
    }
  }

  .ant-btn.primary-button.md.requested-service-button:disabled {
    background: styles.$variables-dark-blue;
    border-color: styles.$variables-dark-blue;
    color: styles.$variables-white;
  }
}
