@use '../../styles';

.performance {
    .ant-tabs-tab {
      padding: 5px 20px;
      font-size: 16px;

      .ant-tabs-tab-btn {
        margin: 0 auto;
      }
    }

    &__header {
      @include styles.mixins-style-flex-row-space-between;
      margin-bottom: 20px;
    }

    &__actions {
      .ant-select-selector {
        width: 110px !important;
      }
    }
}