@use '../../../styles';

.expandable-table {
  .collapse {
    .expandable {
      .ant-collapse-content {
        &-box {
          padding: 20px;
        }
      }

      .ant-table {
        &-wrapper {
          border-radius: 8px;
          overflow: hidden;
          box-shadow: styles.$variables-vertical-box-shadow;
        }

        &-tbody {
          .ant-table-cell {
            @include styles.mixins-style-font-format(14px, 20px, 400);
            color: styles.$variables-dark-grey;
          }
        }

        &-thead {
          .ant-table-cell {
            @include styles.mixins-style-border(none);
            @include styles.mixins-style-font-format(16px, 24px, 700, capitalize);
            background: styles.$variables-extended-light-blue;
            color: styles.$variables-dark-grey;
            padding: 12px 16px;

            &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
              content: none;
            }
          }
        }
      }
    }
  }
}
