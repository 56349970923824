@use '../../../../styles';

.document-box {
  @include styles.mixins-style-width-height(100%, 42px);
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 8px);
  @include styles.mixins-style-flex-row-space-between;
  background-color: styles.$variables-white;
  padding: 16px;

  &__label {
    @include styles.mixins-style-font-format(18px, 24px, 700);
  }

  &__file-name {
    @include styles.mixins-style-font-format(14px, 20px, 400);
    @include styles.mixins-style-capitalize-first-word-only;
    @include styles.mixins-style-text-truncate;
    max-width: 92%;
    height: 20px;
    display: flex;
    flex: none;
    margin-top: 8px;
  }

  .ant-btn-dangerous {
    border: none;
  }
}
