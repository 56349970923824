@use '../../../styles';

.steps-form {
  border-radius: 16px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 24px 24px 0px #2f405112;
  display: flex;
  height: 100%;

  &__fields {
    padding: 100px 40px;
    overflow-y: scroll;
    height: 100%;
    flex: 1;

    .input .ant-form-item-label label {
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .ant-btn {
      flex: 0.15;
    }
  }
}
