@use '../../../../styles';

.services-overview {
  .services {
    margin: 30px 0;

    &-column {
      @include styles.mixins-style-flex-row-v-center;
      gap: 16px;

      .overview-column-name {
        width: calc(100% - 46px);
      }

      &.ant-avatar {
        @include styles.mixins-style-width-height(30px, 30px);
        object-fit: contain;

        img {
          object-fit: contain;
        }
      }

      &--about {
        @include styles.mixins-style-text-truncate;
        width: 500px;
      }
    }
  }
}
