@use '../../../../../styles';

.event-buttons {
  &__container {
    @include styles.mixins-style-flex-row;
    gap: 16px;

    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        @include styles.mixins-style-width-height(123px, 32px);
        @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
        padding: 4px 16px;
        background: styles.$variables-white;
      }

      .ant-select-selection {
        &-placeholder, &-item {
          @include styles.mixins-style-font-format(14px, 24px, 700);
          color: styles.$variables-dark-grey;
        }

        &-item {
          @include styles.mixins-style-capitalize-first-word-only;
        }
      }

      .ant-select-arrow {
        color: styles.$variables-dark-grey;

        svg {
          @include styles.mixins-style-width-height(16px, 16px);
        }
      }
    }

    .event-response {
      &__interested,
      &__going,
      &__not-going {
        @include styles.mixins-style-font-format(14px, 24px, 700);
        @include styles.mixins-style-flex-row-v-center;
        @include styles.mixins-style-width-height(150px, 32px);
        padding: 4px 16px;
        gap: 8px;

        span {
          width: 100%
        }
      }

      &__interested {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
        color: styles.$variables-dark-grey;
        background: styles.$variables-white;
      }

      &__going {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 16px);
        color: styles.$variables-white;
        background: styles.$variables-primary-blue;
      }

      &__not-going {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-error, 16px);
        color: styles.$variables-primary-error;
        background: styles.$variables-white;
      }
    }
  }
}

.event-buttons__rsvp-dropdown.ant-select-dropdown {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
  box-shadow: styles.$variables-vertical-box-shadow;
  padding: 8px 0;

  .ant-select-item-option-content {
    @include styles.mixins-style-font-format(14px, 20px, 400);
    @include styles.mixins-style-capitalize-first-word-only;
    color: styles.$variables-dark-grey;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    .ant-select-item-option-content {
      color: styles.$variables-white;
    }
  }
}
