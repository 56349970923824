@use '../../../../styles';

.request-details {
  .request-info {
    width: 100%;
    margin-bottom: 20px;

    .overview {
      margin-bottom: auto;
    }

    &__container {
      flex: 1 1 0;
    }

    &__image-container {
      @include styles.mixins-style-width-height(100%, 200px);
      padding: 30px 60px;
      background: styles.$variables-light-blue-1;
      border-radius: 8px;
      position: relative;

      .request-info__image {
        @include styles.mixins-style-width-height(100%, 100%);
        object-fit: contain;
      }
    }

    &__request-details {
      margin-top: 20px;

      &__request-number, &__request-date {
        color: styles.$variables-dark-blue-2;
        margin: 0;
      }

      &__request-number {
        @include styles.mixins-style-font-format(16px, normal, bold);
        margin-bottom: 10px;
      }

      &__request-date {
        @include styles.mixins-style-font-format(12px, normal, normal);
      }
    }
  }
}
