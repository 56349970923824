@use '../../../styles';

.filter-buttons-container {
  @include styles.mixins-style-flex-row;
  @include styles.mixins-style-width-height(100%, 50px);
  gap: 8px;
  flex: 1;
  overflow-y: scroll;

  .secondary-button.md {
    font-weight: 500;

    &.filter-is-active {
      font-weight: 700;
      background-color: styles.$variables-extended-dark-blue-stroke;
      color: styles.$variables-dark-grey;
      border-color: styles.$variables-tertiary-grey;
    }
  }

  &::-webkit-scrollbar {
    width: 0.2em;
    background-color: transparent;
  }
}

