@use '../../styles';


.wallet-container {
  @include styles.mixins-style-flex-column;
  @include styles.mixins-style-width-height(100%, auto);
  gap: 20px;

  .startup-wallet__amount-info {
    @include styles.mixins-style-flex-row-space-between;

    .amount {
      .amount__value {
        @include styles.mixins-style-font-format(25px, normal, 600);
      }
    }
  }

  .search-bar {
    margin: 0;
    border-radius: 4px 0;
  }
}
