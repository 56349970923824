@use '../../../../styles';

.request-proposal {
  &__container {
    width: 75%;
  }

  &__content {
    margin-top: 20px;
  }
}
