@use '../../styles';

.dashboard {
  padding-bottom: 40px;

  .dashboard-logo-title {
    @include styles.mixins-style-flex-row;
    gap: 24px;

    .dashboard-logo__container {
      @include styles.mixins-style-width-height(72px, 72px);

      .dashboard-logo {
        @include styles.mixins-style-width-height(100%, 100%);
        object-fit: cover;
      }
    }
  }

  .dashboard-content {
    @include styles.mixins-style-flex-column;
    gap: 44px;
  }
}
