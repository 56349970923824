@use '../../styles';

.change-password-page {
  @include styles.mixins-style-flex-center-column;

  .change-password-container {
    @include styles.mixins-style-flex-center-column;
    @include styles.mixins-style-width-height(100%,100%);

    .sub-form {
      @include styles.mixins-style-flex-column;

      .heading {
       @include styles.mixins-style-font-format(32px, normal, 700, capitalize);
        margin: 0 0 2rem;
        width: 100%;
        max-width: 470px;
        text-align: left;
        color: styles.$variables-dark-blue-1;
       }

      .input-heading {
        margin: 0 0 0.5rem;
        width: 100%;
        max-width: 470px;
        text-align: left;
        color: styles.$variables-dark-blue-1;
      }

      .change-password-button {
        @include styles.mixins-style-width-height(98%,45px);
        max-width: 470px;
        background: styles.$variables-dark-blue-1;
        border-radius: 8px;
        border: none;
        margin-bottom: 2rem;
        color: styles.$variables-white;
        cursor: pointer;
      }
    }
  }
}
