@use '../../../styles';

.breadcrumb-container {
  align-items: center;

  .back-button {
    color: styles.$variables-dark-blue-2;
    font-size: 25px;
  }

  .title {
    color: styles.$variables-dark-blue-2;
    @include styles.mixins-style-font-format(15px, normal, normal, capitalize);
    @include styles.mixins-style-flex-center;
    margin-bottom: 15px;
  }
}
