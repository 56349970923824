@use '../../../styles';

.image-container {
  margin-bottom: 20px;
  position: relative;
  object-fit: contain;
  width: fit-content;

  .image-tag {
    @include styles.mixins-style-width-height(264px, 150px);
    display: block;
    border-radius: 8px;
  }

  .ant-image-img {
    object-fit: cover;
  }

  .edit-icon-wrap {
    @include styles.mixins-style-width-height(30px, 30px);
    @include styles.mixins-style-flex-center-column;
    border-radius: 30px;
    padding: 5px;
    background: styles.$variables-white;
    align-self: flex-end;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    margin: 10px;

    .edit-icon {
      fill: styles.$variables-dark-blue-1;

      svg {
        fill: styles.$variables-dark-blue-1;
      }
  }
}
}
