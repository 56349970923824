@use '../../../../styles';

.overviews {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  background: styles.$variables-white;
  box-shadow: styles.$variables-vertical-box-shadow;
  padding: 16px;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;

  &__transparent-container {
    @include styles.mixins-style-flex-column;
    gap: 16px;
  }

  .overview__title {
    color: styles.$variables-dark-grey;
  }

  .overview {
    background-color: styles.$variables-white;

    &__card {
      @include styles.mixins-style-flex-row;
      justify-content: space-between;
      align-items: center;

      .overview-data-column {
        .overview-data-label, .overview-data-text {
          color: styles.$variables-dark-grey;
        }
      }
    }
  }

  .ant-btn {
    @include styles.mixins-style-flex-center;
    background-color: styles.$variables-white;
    border-color: styles.$variables-tertiary-grey;
    color: styles.$variables-dark-grey;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 700;
    gap: 8px;
    letter-spacing: normal;
    line-height: 24px;
    padding: 12px 16px;
    text-transform: none;

    &:hover {
      color: styles.$variables-primary-grey;
      background-color: styles.$variables-light-grey;
    }
  }
}
