@use '../../../styles';

.profile-overview {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  @include styles.mixins-style-flex-row;
  gap: 16px;
  background: styles.$variables-white;
  box-shadow: styles.$variables-vertical-box-shadow;
  padding: 16px;
  width: 100%;

  &__left-content-container {
    @include styles.mixins-style-flex-column;
    gap: 8px;

    .profile-overview {
      &__image-container {
        @include styles.mixins-style-width-height(264px, 150px);
        border-radius: 8px;
        overflow: hidden;

        .profile-overview__image {
          @include styles.mixins-style-width-height(100%, 100%);
          object-fit: contain;

          &.cover-image {
            object-fit: cover;
          }
        }

        .profile-overview__no-image-container {
          @include styles.mixins-style-flex-center;
          background-color: styles.$variables-extended-light-blue;
          border-radius: 8px;
          height: 100%;

          svg {
            @include styles.mixins-style-width-height(32px, 32px);
            color: styles.$variables-dark-grey;
          }
        }
      }

      &__left-content-item {
        @include styles.mixins-style-flex-column;
        gap: 8px;
      }

      &__heading {
        @include styles.mixins-style-font-format(18px, 22px, 700, capitalize);
        color: styles.$variables-dark-grey;
        margin: 0;
      }

      &__subheading-container {
        @include styles.mixins-style-flex-row;
        gap: 4px;

        svg {
          @include styles.mixins-style-width-height(16px, 16px);
          color: styles.$variables-white;
          background: styles.$variables-primary-blue;
          border-radius: 8px;
        }

        .profile-overview__subheading {
          @include styles.mixins-style-font-format(12px, 20px, 400);
        }
      }
    }
  }

  &__right-content {
    @include styles.mixins-style-flex-column;
    width: 100%;
    gap: 8px;

    &-container {
      @include styles.mixins-style-flex-row;
      flex-wrap: wrap;
      flex: 0 1 100%;
      gap: 8px;
    }

    &-container-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-auto-flow: column;
      gap: 8px;
    }
  }

  .profile-overview__right-content-item {
    @include styles.mixins-style-flex-column;
    flex: 1 1 calc(50% - 8px);

    &:not(:first-child), &:not(:nth-child(2)) {
      margin-top: 8px;
    }

    &--label, &--value {
      color: styles.$variables-dark-grey;
    }

    &--label {
      @include styles.mixins-style-font-format(12px, 20px, 400, capitalize);
    }

    &--value {
      @include styles.mixins-style-font-format(14px, 20px, 700);
    }
  }

  .profile-overview__social-media {
    @include styles.mixins-style-flex-row-v-center;
    width: 100%;
    gap: 8px;

    &-item {
      @include styles.mixins-style-flex-center;
      line-height: 20px;
      height: 20px;
    }
  }
}
