@use '../../styles';

.ant-layout.layout-with-sidebar {
  &, .ant-layout {
    background: transparent;
    height: 100vh;
    min-width: 1000px;
    overflow-y: hidden;
  }

  .content {
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;

    &:has(.content-notification) {
      .content-container {
        min-height: calc(100vh - 152px);
      }
    }

    &:not(:has(.content-notification)) {
      .content-container {
        min-height: calc(100vh - 122px);
      }
    }

    &-container {
      height: max-content;
      padding: 32px 24px 0;
    }

    &-notification {
      padding: 32px 24px 30px;
    }

    .notification-not-community {
      padding: 32px 24px 0;
    }
  }

  .layout-with-sidebar__notifications {
    width: 400px !important;
    transition: .4s;
    z-index: 9999;
    position: fixed !important;
    right: 30px;
    top: 100px;
    opacity: 1;
    filter: blur(0);

    &.hidden {
      max-height: 0;
      opacity: 0;
      z-index: -1;
      filter: blur(1);
    }
  }
}
