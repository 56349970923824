@use '../../styles';

.service-providers-overview, .services-overview {
  @include styles.mixins-style-flex-column;
  gap: 15px;

  .tab-buttons-lg {
    @include styles.mixins-style-flex-row;
    width: 100%;
  }
}

.service-providers-overview {
  .service-providers {
    margin: 30px 0;

    &-column {
      @include styles.mixins-style-flex-row-v-center;
      gap: 16px;

      .ant-avatar {
        @include styles.mixins-style-width-height(30px, 30px);

        img {
          object-fit: contain;
        }
      }

      .overview-column-name {
        width: calc(100% - 46px);
      }

      &--about {
        @include styles.mixins-style-text-truncate;
        width: 250px;
        margin: 0;
      }
    }
  }
}

.services-overview {

  .title-with-actions {
    margin-bottom: 16px;
  }

  .services {
    &-column {
      @include styles.mixins-style-flex-row-v-center;

      &--about {
        @include styles.mixins-style-text-truncate;
        width: 500px;
      }
    }
  }

  &__filters-tag-container, &__filters-tag {
    @include styles.mixins-style-flex-start;
  }

  .ant-tabs {
    height: 50px;

    .ant-tabs-nav {
      &-operations {
        display: none;
      }
    }
  }
}
