@use '../../../../styles';

.acceptance-letter {
  width: 100%;
  @include styles.mixins-style-flex-column;
  align-items: flex-start;

  &__container {
    @include styles.mixins-style-flex-row-space-between;
    @include styles.mixins-style-border(solid, 0, transparent, 8px);
    background: styles.$variables-extended-light-blue;
    padding: 15px;
  }

  &__accept-button.ant-btn {
    @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
    color: styles.$variables-primary-blue;
  }

  &__accepted-text {
    @include styles.mixins-style-font-format(14px);
    font-style: italic;
    color: styles.$variables-primary-blue;
    margin-left: 5px;
  }

  &__accepted-container {
    color: styles.$variables-primary-blue;
    @include styles.mixins-style-flex-center-row;
  }
}
