@use '../../../styles';

.date-time-from-to__row {
    display: flex;
    gap: 10px;
    flex-basis: 48%;
    align-items: flex-end;

    @media(max-width: 1000px) {
        @include styles.mixins-style-flex-column;
        flex-basis: auto;
        width: 100%;
        gap: 20px;
    }
}
