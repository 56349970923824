@use '../../../../../../styles';

.service-drawer {
    &__headings {
        @include styles.mixins-style-flex-start;
        flex: 1;
        gap: 58px;
    }

    &__buttons {
        @include styles.mixins-style-flex-start;
        gap: 10px;

        .ant-btn {
            flex: 0.3;
            text-transform: capitalize;
        }
    }
}