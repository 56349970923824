@use '../../../styles';

.edit-my-profile {
  @include styles.mixins-style-flex-column;
  padding: 25px 0;
  width: 100%;

  &__fields {
    @include styles.mixins-style-flex-column;
    gap: 22px;
    width: 100%;

    .primary-button {
      align-self: flex-end;
      margin-bottom: 50px;
    }
  }
}
