@use '../../../../../../../styles';

.noDataContainer {
    margin-top: 20%;
    align-items: center;
    text-align: center;
    @include styles.mixins-style-font-format(24px, 24px, bold, capitalize);
     color: styles.$variables-dark-grey;
     padding: 20px;
     gap: 8px;

    &__title {
        align-self: center;
    }
}