@use "../../../../../styles";

.ant-checkbox-group.checkbox-group {
  @include styles.mixins-style-flex-column;
  gap: 10px;

  .ant-checkbox-group-item {
    color: styles.$variables-dark-blue-2;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: styles.$variables-dark-blue-2;
    border-color: styles.$variables-dark-blue-2;
  }
}
