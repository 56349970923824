@use '../../styles';

.add-missing-details__container {
  @include styles.mixins-style-width-height(100%, 100%);
  overflow-y: scroll;
  padding: 25px 0;

  &__title {
    @include styles.mixins-style-flex-start;
    @include styles.mixins-style-font-format(24px, normal, bold, capitalize);
    margin-bottom: 50px;
    margin-left: calc((100% - 860px) / 2);

    .title {
      color: styles.$variables-dark-blue-1;
    }
  }

  &__rows {
    @include styles.mixins-style-flex-center;

    .ant-form.ant-form-horizontal {
      @include styles.mixins-style-flex-start;
      flex-wrap: wrap;
      gap: 10px 20px;
      width: 860px;

      .input {
        flex: 0 0 calc(50% - 10px);
      }

      .add-missing-details__container__column-inputs {
        @include styles.mixins-style-flex-column-start-center;
        gap: 10px;
        width: 100%;

        .input {
          width: calc(50% - 10px);

          .image-container {
            margin: 0;
          }
        }
      }
    }

    .title {
      @include styles.mixins-style-flex-start;
      @include styles.mixins-style-font-format(14px, normal, bold, capitalize);
      color: styles.$variables-dark-blue-1;
    }

    .submit-button {
      @include styles.mixins-style-flex-row-end;
      width: 100%;

      &__button {
        @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
        margin-top: 10px;
        background: styles.$variables-dark-blue-1;
        border-radius: 8px;
        height: 40px;
        width: 200px;
      }

      .startup-status {
        @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
        color: styles.$variables-primary-error;
      }
    }

    .icon-color {
      color: styles.$variables-dark-blue-1;
    }

    .team-members {
      &__invite {
        border-color: transparent;
        color: styles.$variables-dark-blue-1;
        @include styles.mixins-style-font-format(16px, normal, bold, uppercase);
        background-color: transparent;
      }
    }
  }

  &__column {
    text-align: start;

    &__adgm {
      width: 100%;
    }

    &__title {
      @include styles.mixins-style-font-format(24px, normal, bold, capitalize);
      color: styles.$variables-dark-blue-1;
    }
  }

  .adgm-preview {
    margin-top: 10px;
  }
}
