@use '../../../styles';

.company-deck-file {
  @include styles.mixins-style-flex-row-space-between;
  @include styles.mixins-style-border(dashed, 1px, styles.$variables-dark-blue-2, 8px);
  @include styles.mixins-style-width-height(50%, auto);
  padding: 10px;
  flex: auto;

  &__info {
    @include styles.mixins-style-flex-row-v-center;
    @include styles.mixins-style-width-height(50%, auto);
    flex: 1;

    &--container {
      @include styles.mixins-style-flex-column-start-center;

      max-width: 80px;
      overflow: hidden;
    }

    &--icon {
      @include styles.mixins-style-width-height(63px, 69px);

      min-width: 63px;
    }

    &--name {
      text-align: start;
      @include styles.mixins-style-font-format(14px, normal, bold);
      color: styles.$variables-primary-blue;
      margin-bottom: 5px;
    }
  }

  &__size {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
    color: styles.$variables-extended-dark-blue;
    text-align: start;
  }

  &__buttons {
    @include styles.mixins-style-flex-column;

    .ant-btn.primary-button.md {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
