@use '../../styles';

.loading-container {
  @include styles.mixins-style-width-height(100%, 100%);
  @include styles.mixins-style-flex-center-column;
}

.logo-container {
  width: fit-content;
  margin-bottom: 20px;
}

.edit-service-provider,
.service-provider-more-details {
  padding: 30px 30px 30px 0;

  &-form {
    @include styles.mixins-style-flex-column;
    gap: 16px;
    width: 100%;

    .form-builder__row--one-column {
      margin-bottom: 0;
    }

    .company-deck-file {
      width: 100%;

      &__buttons--btn {
       width: 120px;
      }
    }
  }
}

.edit-service-provider-form {
  @include styles.mixins-style-flex-column;
  .primary-button {
    align-self: flex-end;
  }
}

.service-provider-more-details {
  max-width: 75%;
}
