@use '../../../styles';

.performance-review-drawer {

  .submit__wrap {
    @include styles.mixins-style-flex-row-end;

    .button-with-prompt {
      width: 100%;
    }

    .secondary-button {
      margin-right: 16px;   
    }
  }
}
