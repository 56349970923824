@use '../../../../styles';

.adgm-license {
  @include styles.mixins-style-flex-column-start;
  gap: 8px;
  margin-top: 20px;

  .ant-form-item-control-input-content {
    @include styles.mixins-style-flex-start;
    margin-bottom: 10px;
  }

  &__fields {
    @include styles.mixins-style-flex-column;
    width: 100%;
    gap: 10px 20px;

    &.adgm-license__fields__add-missing-details {
      flex-direction: row;
      flex-wrap: wrap;

      .input {
        flex: 0 0 calc(50% - 10px);
      }
    }

    &__column-inputs {
      flex: 0 0 100%;
      width: 100%;

      .input {
        & .ant-form-item-control-input-content {
          & span {
            width: 100%;
          }

          & .dragger.ant-upload.ant-upload-drag {
            flex: 0 0 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
