@use '../../../../../styles';

.password-input-container {
  max-width: 470px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    margin-bottom: 1rem;
  }

  .input .ant-form-item-control-input-content {
    //input {
    //  max-width: 460px;
    //  font-size: 16px;
    //  color: styles.$variables-dark-blue-1;
    //  background: styles.$variables-light-blue-3;
    //  border: none;
    //  padding: 0 8px;
    //  margin-bottom: 0.5rem;
    //  &:active,
    //  &:focus {
    //    outline: none;
    //  }
    //}

    //.ant-input {
    //  &:active,
    //  &:hover,
    //  &:focus {
    //    outline: none;
    //  }
    //}
  }
}
