@use '../../../../styles';

.upcoming-events {
  .title-with-actions {
    margin-bottom: 20px;
  }

  &__container {
    @include styles.mixins-style-flex-row;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px 24px;

    .event-card {
      flex: 0 0 calc((100% - 48px) / 3);
    }
  }
}
