@use '../../styles';

.onboarding-progress {
  @include styles.mixins-style-flex-column;
  align-items: center;
  gap: 24px;

  &-info {
    width: 100%;
    flex: 1;
  }

  &-stepper {
    width: 100%;
    max-width: 800px;

    .ant-steps-item-title {
      line-height: 1.2;
    }

    .ant-steps-item-icon {
      border: none;
    }

    .ant-steps-item {
      .ant-steps-item-icon > .ant-steps-icon {
        color: #fff;
      }

      &.ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: #36806E;
        }

        .ant-steps-item-content > .ant-steps-item-title {
          color: #36806E;
        }
      }

      &.ant-steps-item-active {
        .ant-steps-item-icon {
          background-color: #B2834B;
        }
        .ant-steps-item-content > .ant-steps-item-title {
          color: #B2834B;
        }
      }

      &.ant-steps-item-wait {
        .ant-steps-item-icon {
          background-color: #4B74B2;
        }
        .ant-steps-item-content > .ant-steps-item-title {
          color: #4B74B2;
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 14px;
        }
      }
    }
  }
}
