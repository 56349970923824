@use '../../../../styles';

.service-request {

    &__total {
        padding: 20px 0;
        @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 0, (top)); 
        display: flex;
        justify-content: space-between;
    }

    &__total-text,
    &__total-amount {
        @include styles.mixins-style-font-format(18px, 24px, 700);
    }
    &__total-amount{
        display: flex;
        align-items: center;

        .vat-text{
            font-size: 13px;
            font-weight: 600;
        }
    }
    &__spinner{
        width: 22px;
        height: 22px;
        border-radius: 50px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: conic-gradient(white, #d8d8d8);
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    &__loader{
        width: 14px;
        height: 14px;
        border-radius: 50px;
        background: white;
    }
}
.additional-options-container {
    @include styles.mixins-style-flex-row;
    align-items: center;
    margin-bottom: 8px;

    .switch-wrap {
        @include styles.mixins-style-flex-row;
        align-items: center;

        &-label {
            margin: 0 0 0 8px ;
        }

        &-value {
            @include styles.mixins-style-font-format(14px, 24px, 700);
            margin: 0 0 0 16px ;
        }
    }
}