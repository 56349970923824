@use '../../../styles';

.filter-container,
.display-container {
  @include styles.mixins-style-flex-row;
}

.filters-dropdown {
  &__container {
    position: relative;

    &--shown {
      width: 356px;
      height: 40px;
    }
  }

  &__title-container {
    @include styles.mixins-style-flex-row-space-between;
    position: sticky;
    z-index: 10;
    padding: 16px 0;
    top: 0;
    background: styles.$variables-white;

    .filters-dropdown__title {
      @include styles.mixins-style-font-format(18px, 22px, 700);
      color: styles.$variables-dark-grey;
      margin: 0;
    }
  }

  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  background: styles.$variables-white;
  width: 356px;
  padding: 0 16px 16px;
  position: absolute;
  z-index: 100;
  right: 0;
  max-height: 600px;
  overflow-y: scroll;

  @include styles.mixins-style-flex-column;
  gap: 16px;

  .input .ant-form-item-label label, .heading {
    @include styles.mixins-style-font-format(14px, 24px, 700);
    color: styles.$variables-dark-grey;
  }

  &--divider:not(:last-child) {
    @include styles.mixins-style-width-height(100%, 1px);
    background: styles.$variables-grey;
    opacity: 0.3;
  }

  &--divider:last-child {
    display: none;
  }

  .input label.input-radio.ant-radio-wrapper {
    @include styles.mixins-style-font-format(12px, 20px, 400);
    color: styles.$variables-dark-grey;
  }

  &__amount-fields {
    @include styles.mixins-style-flex-column;
    gap: 16px;
  }

  .primary-button {
    align-self: flex-end;
  }

  .filters-buttons {
    @include styles.mixins-style-flex-row-end;
    gap: 8px;
    width: 100%;

    &--button {
      @include styles.mixins-style-font-format(14px, 24px, 700);
      @include styles.mixins-style-flex-center;
      border: none;
      background: transparent;
      color: styles.$variables-primary-blue;
      cursor: pointer;
      padding: 0;

      @include styles.mixins-style-hover {
        color: styles.$variables-dark-blue;
      }
    }
  }
}

.search-bar {
  @include styles.mixins-style-width-height(100%, auto);
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  @include styles.mixins-style-flex-row-space-between;
  padding: 10px;
  background-color: styles.$variables-white;
  z-index: 20;
  position: relative;

  &:not(.search-bar-only-filter) {
    box-shadow: styles.$variables-vertical-box-shadow;
  }

  &.search-bar-no-button {
    justify-content: flex-end;
  }

  .add-button {
    @include styles.mixins-style-flex-center;
    @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
    color: styles.$variables-dark-blue-1;
  }

  .info-text {
    @include styles.mixins-style-font-format(20px, normal, bold, capitalize);
    color: styles.$variables-dark-blue-1;
  }

  .filter-container {
    @include styles.mixins-style-width-height(50%, auto);
    align-self: flex-end;
    gap: 10px;
    flex: 0.8;
    max-width: 700px;
    margin-left: auto;

    .input.search-bar__search-field {
      flex: 1 1;
    }

    .input.type-button-container {
      width: 150px;
    }

    &.filter-container-no-input {
      justify-content: flex-end;
    }

    .input {
      .ant-form-item {
        margin: 0;
      }
    }

    .display-container {
      @include styles.mixins-style-width-height(60px, auto);
      align-items: center;
      margin-left: 15px;

      .display-button {
        @include styles.mixins-style-border(none);
        @include styles.mixins-style-flex-center;
        @include styles.mixins-style-width-height(30px, 30px);
        background: transparent;
        color: styles.$variables-dark-blue-2;
      }

      .display-button-selected {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-white, 20px);
        background: styles.$variables-dark-blue;
        color: styles.$variables-white;
      }
    }

    .filter-button {
      @include styles.mixins-style-font-format(14px, 24px, 700, uppercase);
      @include styles.mixins-style-border(solid, 0, transparent, 4px);
    }
  }
}

.table-filter-button__container {
  position: relative;

  .filter-dot {
    @include styles.mixins-style-width-height(8px, 8px);
    border-radius: 4px;
    background: styles.$variables-primary-warning;
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

.table-export-button__container {
  display: flex;
  align-items: center;
  position: relative;

  .export-button {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #0062ED;
  }
}

.ant-dropdown:has(.search-bar-dropdown) {
  background: styles.$variables-white;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 0, (bottom, right, left));
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 150px;
  top: 200px!important;
  z-index: 0!important;

  .search-bar-dropdown {
    padding: 30px 16px 10px;
  }
}


.toggle-user-type__container {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
        margin: 0;
    }

    .toggle-user-type-switch__container {
        display: flex;
        flex-wrap: nowrap;
        gap: 0.5rem;
        align-items: center;

        &:last-child {
            .toggle-switch__item {
                p {
                    width: 120px;
                }
            }
        }

        .toggle-switch__item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}
