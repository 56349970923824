@use '../../../../../../../../styles';

.sort-by-popover {
    @include styles.mixins-style-flex-column;  

    .ant-btn-link {
        font-weight: 600;
        color: styles.$variables-dark-blue-2;
        background-color: transparent;
        width: auto;
        text-align: left;
    
        &:hover,
        &:focus {
          color: styles.$variables-light-blue-6;
        }
    }
}