@use '../../../../styles';

.partners-overview-tabs {
  @include styles.mixins-style-flex-row;
  width: 85%;
  gap: 36px;

  .partners-overview-tabs-container {
    display: flex;
    gap: 32px;

    &__tab {
      @include styles.mixins-style-font-format(14px, 16px, 700px, capitalize);
      @include styles.mixins-style-flex-column;
      width: fit-content;
      align-items: center;
      padding: 8px 10px;
      left: 285px;
      top: 122px;

      .sidebar-icon {
        padding: 8px;
        background-color: transparent;
        border-radius: 12px;
        gap: 8px;
        width: fit-content;
        margin-bottom: 8px;
      }

      .sidebar-icon svg {
        @include styles.mixins-style-width-height(24px, 24px);
      }
    }

    .active-tab {
      margin-bottom: 16px;
      border-bottom: 1px solid styles.$variables-primary-blue;
      width: 100%;

      .sidebar-icon {
        background-color: styles.$variables-primary-blue;
      }

      .sidebar-icon svg {
        @include styles.mixins-style-width-height(24px, 24px);
        fill: white;
      }
    }
  }
}
