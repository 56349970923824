@use '../../../styles';

.table-action-buttons {
  @include styles.mixins-style-flex-row-v-center;
  gap: 16px;
  
  .ant-btn.ghost-button {
    height: auto;
    padding: 0 !important;
    font-weight: 400;
    color: styles.$variables-dark-grey;
    border: none !important;

    &:hover, &:focus, &:active {
      background: transparent;
    }

    &:not(.delete):hover {
      color: styles.$variables-primary-blue;
    }

    &.delete:hover {
      color: styles.$variables-extended-red;
    }
  }

  .divider {
    @include styles.mixins-style-width-height(1px, 16px);
    background-color: styles.$variables-primary-grey;
  }
}