$grid-breakpoints: (
  'xs': 0,
  'sm': 576px,
  'm': 768px,
  'l': 992px,
  'xl': 1200px,
  'xxl': 1400px,
) !default;

// valid border sides
$valid-sides: top, left, bottom, right;

// valid directions for linear gradient
$valid-directions: to top, to left, to bottom, to right;

// valid types for radial gradient
$valid-radial-types: circle, ellipsis;

// valid border styles
$border-styles: dotted, dashed, hidden, none, solid, double, groove, ridge, inset, outset;

// valid text transform values
$text-transform-values: uppercase, lowercase, capitalize, none, initial, inherit;

// colors

// old colors:
$light-blue-1: #eff6ff;
$light-blue-2: #e2edff;
$light-blue-3: #cde2ff;
$light-blue-4: #a2c4f3;
$light-blue-5: #93b7e9;
$light-blue-6: #5490e4;
$light-blue-7: #a6bbdd;
$dark-blue-1: #0065f3;
$dark-blue-2: #116df3;
$red: #AA4344;
$light-red: #FAA0A0;

// new colors from design
$white: #FFFFFF;
$primary-blue: #0062ED;
$secondary-blue: #0058D4;
$dark-blue: #0042A1;
$primary-light-blue: #EBF3FF;
$dark-grey: #2F4051;
$primary-grey: #767676;
$secondary-grey: #C5C5C5;
$tertiary-grey: #CFD7E0;
$light-grey: #D4D4D4;
$grey: #BEBEBE;

$extended-green: #36806E;
$extended-green-stroke: #C4F0C2;
$extended-purple: #6C4BB2;
$extended-purple-stroke: #DCCEFF;
$extended-dark-blue: #4B74B2;
$extended-dark-blue-stroke: #CED9FF;
$extended-teal: #4B86B3;
$extended-teal-stroke: #CEEAFF;
$extended-orange: #B2834B;
$extended-orange-stroke: #FFE8CE;
$extended-red: #B24B4B;
$extended-red-stroke: #FFCECE;
$extended-light-blue: #EAF2FE;
$extended-active-blue: #D9E9FF;
$extended-light-blue-2: #DBEAFF;

$primary-info: #0172CB;
$secondary-info: #C7E3FF;
$primary-warning: #DF7B00;
$secondary-warning: #FFDEC7;
$primary-success: #28A138;
$secondary-success: #D9FFC7;
$primary-error: #DA1E28;
$secondary-error: #FFC7C7;

// shadow
$box-shadow: 0 8px 15px 2px rgba(47, 64, 81, 0.07);
$vertical-box-shadow: 0 24px 24px rgba(47, 64, 81, 0.07);
$spread-shadow: 0px 0px 10px 3px rgba(47, 64, 81, 0.07);
