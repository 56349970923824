@use '../../../../../../styles';

.add-new-template {

    .submit__wrap {
        @include styles.mixins-style-flex-row-end;
        margin-top: 5rem;

        .secondary-button {
          margin-right: 16px;   
        }
    }
}