@use '../../../styles';

.overview {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  box-shadow: styles.$variables-vertical-box-shadow;
  @include styles.mixins-style-flex-column;
  background: styles.$variables-white;
  flex: 2 1 0;
  overflow: hidden;
  text-align: start;

  &__title {
    @include styles.mixins-style-font-format(18px, normal, bold, capitalize);
    margin: 0;
  }

  &__container {
    padding: 20px;
    width: 100%;

  }

  &__card {
    justify-content: space-between;
    color: styles.$variables-dark-grey;
    width: 100%;

    &--paragraph {
      @include styles.mixins-style-font-format(12px);
      color: styles.$variables-dark-blue-2;
      text-align: justify;
    }

    &-data {
      width: 50%;
      @include styles.mixins-style-flex-row-space-between
    }
  }

  &__header {
    @include styles.mixins-style-flex-row-space-between;
    margin-bottom: 20px;
  }
}
