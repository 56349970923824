@use '../../../../styles';

.adgm-license-upload-form__container {
  @include styles.mixins-style-width-height(100%, 100%);
  padding-bottom: 25px;

  .ant-form.ant-form-horizontal {
    @include styles.mixins-style-flex-start;
    flex-wrap: wrap;
    gap: 10px 20px;

    .input {
      flex: 0 0 calc(50% - 10px);
    }
  }

  .icon-color {
    color: styles.$variables-primary-blue;
  }

  .submit-button {
    @include styles.mixins-style-flex-row-end;
    width: 100%;

    .ant-btn.ant-btn-primary.primary-button:not(.md) {
      width: 120px;
    }
  }

  &__column {
    text-align: start;

    &__adgm {
      width: 100%;
    }

    &__title {
      @include styles.mixins-style-font-format(24px, 32px, 500, capitalize);
      color: styles.$variables-primary-blue;
    }
  }

  .adgm-preview {
    margin-top: 10px;
  }
}
