@use '../../../styles';

.sortIcon {
  margin-left: -5px;
}

.table {
  @include styles.mixins-style-flex-column;
  gap: 20px;

  .ant-table {
    box-shadow: styles.$variables-vertical-box-shadow;
    border-radius: 8px;
    overflow: hidden;
  }

  &:has(.search-bar) {
    .ant-table {
      overflow-x: scroll;
    }
  }

  .ant-table-tbody {
    .ant-table-row.ant-table-row-level-0 {
      .ant-table-cell {
        @include styles.mixins-style-font-format(14px, normal, normal, capitalize);
        color: styles.$variables-dark-grey;
        background: styles.$variables-white;
      }

      &:not(:last-child) {
        .ant-table-cell {
          @include styles.mixins-style-border(solid, 1px, styles.$variables-light-grey, 0, (bottom));
        }
      }
    }
  }

  .ant-table-thead {
    background: styles.$variables-extended-light-blue;
    &:hover, &:focus {
      background: styles.$variables-extended-light-blue;
    }
    .ant-table-cell {
      @include styles.mixins-style-border(none);
      @include styles.mixins-style-font-format(14px, normal, bold, capitalize);
      background: styles.$variables-extended-light-blue;
      color: styles.$variables-dark-grey;

      &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
        content: none;
      }
    }
    .ant-table-column-sorter {
      display: none;
    }
  }
}
