@use '../../../../../styles';

.steps {
  background-color: styles.$variables-extended-light-blue;
  padding: 40px 20px 20px 40px;
  flex: 0.5;
  @include styles.mixins-style-flex-column;
  gap: 40px;
  
  &__items {
    @include styles.mixins-style-flex-column;
    gap: 20px;
  }

  &__back.ant-btn-ghost {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    color: styles.$variables-dark-grey !important;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
