@use '../../../../styles';

.service-provider-offers {
  .ant-btn {
    align-self: flex-start;
  }

  .ant-btn-link {
    color: styles.$variables-dark-blue-2;
  }
}
