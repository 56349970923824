@use '../../../../styles';

.requests-container .unsent-requests {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
  @include styles.mixins-style-flex-column;
  @include styles.mixins-style-width-height(100%, auto);
  background: styles.$variables-white;
  box-shadow: styles.$variables-vertical-box-shadow;
  padding: 16px;
  gap: 8px;

  .request-row {
    border-color: styles.$variables-tertiary-grey;
  }

  .input {
    margin-top: 4px;

    .ant-form-item-label label {
      @include styles.mixins-style-font-format(14px, 24px, 700);
    }
  }

  &--divider {
    @include styles.mixins-style-width-height(100%, 1px);
    background: styles.$variables-primary-grey;
    opacity: 0.25;
  }

  .requests-total {
    @include styles.mixins-style-flex-column;
    align-self: flex-end;
    gap: 24px;
    margin-top: 16px;

    &--row {
      @include styles.mixins-style-flex-row-space-between;

      &.buttons-container {
        gap: 16px;
      }

      &-item {
        @include styles.mixins-style-font-format(22px, 24px, 700);
        color: styles.$variables-dark-grey;
      }
    }
  }
}
