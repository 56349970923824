@use '../../../../../styles';

.crop-image {
  &.ant-modal-wrap {
    .ant-modal-content {
      border-radius: 16px;
      overflow: hidden;

      .ant-modal-header {
        background: styles.$variables-extended-light-blue;
        height: 96px;
        padding-bottom: 34px;

        .ant-modal-title {
          @include styles.mixins-style-font-format(22px, 28px, 900);
          color: styles.$variables-dark-grey;
        }
      }

      .ant-modal-body {
        padding: 28px!important;
        height: auto;
      }

      .ant-modal-footer {
        @include styles.mixins-style-flex-row-end;
        width: 100%;
        padding: 0 40px 40px;
        gap: 16px;
        border: none;
      }

      .ant-btn {
        &.ant-btn-primary,
        &.ant-btn-default {
          @include styles.mixins-style-font-format(14px, 24px, 700);
          padding: 8px 16px;
          height: 40px;
          box-shadow: none;
        }

        &.ant-btn-primary {
          margin: 0;
          background: styles.$variables-primary-blue;
          @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
        }

        &.ant-btn-default {
          @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
          background: styles.$variables-white;
          color: styles.$variables-dark-grey;
        }
      }
    }
  }
}
