@use '../../../../../styles';

.drawer-buttons {
  @include styles.mixins-style-flex-row-end;
  gap: 10px;

  .ant-btn.ant-btn-default {
    &.primary-button, &.secondary-button, &.ghost-button {
      text-transform: capitalize;
      margin-top: 0;
    }
  }
}
