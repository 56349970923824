@use '../../../../../styles';

.service-request__container {
  @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-2, 8px);
  @include styles.mixins-style-flex-row-space-between-start;
  background-color: styles.$variables-white;
  padding: 16px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 10px 10px rgba(47, 64, 81, 0.07);
  gap: 8px;

  &:hover {
    background: styles.$variables-white;
  }

  &--category-id {
    @include styles.mixins-style-font-format(12px, normal, normal);
    @include styles.mixins-style-flex-row-v-center;
    gap: 4px;
    color: styles.$variables-dark-grey;
    margin-bottom: 5px;
  }

  &--status {
    @include styles.mixins-style-font-format(14px, 22px, 500, capitalize);
    padding: 8px 14px;
  }

  &--about, &--amount {
    @include styles.mixins-style-flex-start;
    color: styles.$variables-dark-grey;
    margin-bottom: 5px;
  }

  &--about {
    @include styles.mixins-style-font-format(16px, normal, 700);
    margin-bottom: 10px;
  }

  &--amount {
    @include styles.mixins-style-font-format(14px, 22px, 400);
  }
}
