@use '../../styles';

.edit-cohort {
  &__container {
    @include styles.mixins-style-width-height(100%, 100%);
    padding: 25px 0;
    overflow-y: scroll;

    .ant-form.ant-form-horizontal {
      @include styles.mixins-style-flex-column;
      gap: 40px;

      .primary-button {
        align-self: flex-end;
      }
    }
  }

  &__fields {
    @include styles.mixins-style-flex-column;
    gap: 22px;
  }

  &__section {
    @include styles.mixins-style-flex-column-start;
    gap: 22px;
    width: 100%;

    &__no-startups {
      @include styles.mixins-style-font-format(14px, 20px, 400);
      color: styles.$variables-dark-grey;
      margin: 0;
    }

    .heading {
      margin-bottom: 0;
    }
  }
}
