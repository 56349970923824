@use '../../../../../../styles';

.add-more-services {
  @include styles.mixins-style-flex-column-start;
  width: 100%;
  gap: 16px;

  &__service-cards--container {
    @include styles.mixins-style-flex-row;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__service-card {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
    @include styles.mixins-style-flex-column;
    gap: 16px;
    width: calc(33.33% - 16px);
    box-shadow: styles.$variables-vertical-box-shadow;
    padding: 16px;
    background: styles.$variables-white;

    &--buttons {
      @include styles.mixins-style-flex-row;
      gap: 8px;

      .ant-btn.secondary-button.md,
      .ant-btn.primary-button.md {
        width: calc(50% - 4px);
      }
    }
  }
}
