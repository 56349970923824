@use '../../../../../../styles';

.discussion-board {
    &__comments {
        @include styles.mixins-style-flex-column;
        gap: 20px;
    }

    &__no-comments {
        padding-top: 10px;
    }

    .form-standalone {
        padding: 40px 0;

        .ant-form-item {
            margin: 0;
        }

        .warning-container {
            @include styles.mixins-style-flex-row-end-center;
            
            svg {
                fill: styles.$variables-primary-warning;
            }
            
            &__tag {
                margin: 0 0 0 9px;
                @include styles.mixins-style-font-format(14px, 20px, 400);
                color: styles.$variables-primary-warning;
            }
        }

        .primary-button {
            align-self: flex-end;
        }
    }
    
    .input {
        .ant-form-item-no-colon {
            font-weight: 700;
            text-transform: none;
        }

        .form-item.ant-form-item:not(.form-item-vertical) .ant-col.ant-form-item-control  {
            width: 100%;
            max-width: 100%;
        }

        textarea {
            padding: 10px;
        }
    }
}