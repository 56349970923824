@use '../../../../styles';

.date-validation-modal {
  &.ant-modal {
    position: static;
    @include styles.mixins-style-width-height(100% !important, 100vh);
    @include styles.mixins-style-flex-center;

    .ant-modal-content {
      width: 558px !important;
      min-height: 252px;
      border-radius: 16px;
      overflow: hidden;

      .ant-modal-header {
        background: styles.$variables-extended-light-blue;
        height: 96px;
        padding-bottom: 34px;

        .ant-modal-title {
          @include styles.mixins-style-font-format(22px, 28px, 900);
          color: styles.$variables-dark-grey;
        }
      }

      .ant-modal-body {
        @include styles.mixins-style-font-format(18px, 20px, 400);
        @include styles.mixins-style-flex-center-column;
        gap: 8px;
        height: auto;
        width: 478px;
        color: styles.$variables-dark-grey;
        padding: 28px 0!important;
      }

      .ant-modal-footer {
        @include styles.mixins-style-flex-row-reverse-end;
        width: 100%;
        padding: 0 40px 40px;
        gap: 16px;
        border: none;

        .ant-btn {
          &.ant-btn-primary,
          &.ant-btn-default {
            @include styles.mixins-style-font-format(14px, 24px, 700);
            padding: 8px 16px;
            height: 40px;
            box-shadow: none;
          }

          &.ant-btn-primary {
            margin: 0;
            background: styles.$variables-primary-blue;
            @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
          }

          &.ant-btn-default {
            @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
            background: styles.$variables-white;
            color: styles.$variables-dark-grey;
          }
        }
      }

      .ant-modal-close {
        top: 42px;
        right: 50px;

        &-x {
          line-height: 12px;

          &, svg {
            @include styles.mixins-style-width-height(12px, 12px);
          }
        }
      }
    }
  }
}
