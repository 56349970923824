@use '../../styles';

.add-cohort {
  max-width: 70vw;

  .form-row {
    @include styles.mixins-style-flex-row;
    gap: 16px;
  }

  .ant-form.ant-form-horizontal {
    @include styles.mixins-style-flex-column;
    gap: 28px;
  }
}
