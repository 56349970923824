@use '../../../styles';

.ant-modal-wrap .ant-modal.preview-modal {
  @include styles.mixins-style-width-height(100% !important, 100vh);
  top: 0;
  max-width: 100%;
  padding: 0;

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    height: calc(100vh - 220px);
    padding: 0;
  }

  .ant-modal-close {
    color: styles.$variables-dark-blue-2;
    top: 34.5px;
    right: 80px;

    .ant-modal-close-x {
      font-size: 32px;
    }
  }

  .ant-modal-header {
    padding: 37px 80px;
  }
}
