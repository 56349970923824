@use '../../../../../styles';

.step-item {
  opacity: 0.3;
  display: flex;
  gap: 20px;
  transition: 0.3s;
  justify-self: end;

  &:hover {
    cursor: default;
    opacity: 0.7;
    transition: 0.3s;
  }

  &--selected {
    opacity: 1 !important;
  }

  &__number,
  &__text {
    font-size: 22px;
    font-weight: 700;
  }

  &__number-circle {
    padding: 20px;
    border-radius: 100px;
    background-color: styles.$variables-primary-blue;
    height: 28px;
    width: 28px;
    @include styles.mixins-style-flex-center;
  }

  &__icon-wrap {
    @include styles.mixins-style-flex-center;

    svg {
      fill: white;
      @include styles.mixins-style-width-height(24px, 24px);
    }
  }

  &__number {
    color: styles.$variables-white;
  }

  &__text {
    color: styles.$variables-primary-blue;
  }
}
