@use '../../../../../styles';

.input {
  .ant-dropdown-menu {
    border-radius: 20px;
  }

  .ant-dropdown-button {
    &.dropdown-button-disabled {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-grey, 20px);
      background: styles.$variables-secondary-grey;

      .ant-dropdown-trigger {
        color: styles.$variables-primary-grey;
      }
    }

    &:not(.dropdown-button-disabled) {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      background: styles.$variables-white;

      &:hover {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);

        .ant-dropdown-trigger svg {
          color: styles.$variables-primary-blue;
        }
      }
    }

    &.dropdown-button {
      @include styles.mixins-style-flex-row-v-center;
      overflow: hidden;
      height: 40px;
      padding: 8px 16px;
      position: relative;

      .ant-btn-compact-first-item {
        width: 100%;
        text-align: left;
      }

      .ant-dropdown-trigger {
        @include styles.mixins-style-width-height(calc(100% - 32px), 100%);
        @include styles.mixins-style-flex-row-end-center;
        position: absolute;

        svg {
          @include styles.mixins-style-width-height(16px, 16px);
        }

        &[disabled] {
          background: transparent;
        }
      }

      .ant-btn.ant-btn-default {
        border: none;
        padding: 0;
        background: transparent;
        @include styles.mixins-style-font-format(14px, 20px, 400);
      }

      &.ant-btn-icon-only {
        @include styles.mixins-style-width-height(fit-content, fit-content);
      }
    }
  }
}
