@use '../../../../../../styles';

.add-template-drawer {
  &__html {
    margin: 20px 0;
  }

  .html-preview {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
    width: 100%;
    height: 300px;
    margin: 20px 0;
  }

  .drawer-buttons {
    margin-top: 20px;
  }
}

.ant-drawer.drawer {
  .ant-drawer-content-wrapper:has(.add-template-drawer) {
    width: 50vw !important;
    max-width: none;
  }
}
