@use '../../../../styles';

.edit-service-drawer {
  @include styles.mixins-style-flex-column;
  gap: 24px;
  height: 100%;

  .form-builder__row--one-column {
    margin: 0;
  }

  &__buttons {
    @include styles.mixins-style-flex-row-end;
    align-self: flex-end;
    gap: 10px;
    margin-top: auto;

    .ant-btn.primary-btn {
      flex: 0.3;
      text-transform: capitalize;
      margin: 0!important;
    }
  }

  .ant-space-vertical {
    flex-direction: row;
  }

  .input .dragger.ant-upload.ant-upload-drag {
    width: 100%;
  }
}
