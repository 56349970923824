@use '../../../../styles';

.navbar {
  @include styles.mixins-style-flex-row-space-between;
  border-radius: 16px;
  background-color: styles.$variables-white;
  padding: 16px;
  margin: 20px;
  height: 72px;
  gap: 24px;
  box-sizing: border-box;
  box-shadow: styles.$variables-vertical-box-shadow;

  .navbar-right {
    @include styles.mixins-style-flex-center;
    gap: 16px;

    .input {
      width: fit-content;
    }

    .help-wrapper {
      @include styles.mixins-style-flex-center;
      height: 40px;
      color: styles.$variables-dark-grey;
      gap: 8px;

      .help--title {
        @include styles.mixins-style-font-format(14px, 24px, 700, capitalize);
        margin: 0;
      }
    }

    .right-side-icon {
      color: styles.$variables-dark-grey;
      border-color: styles.$variables-tertiary-grey !important;

      &:focus {
        background-color: transparent; 
      }

      &:hover, &:active {
        background-color: styles.$variables-light-blue-1;
      }

      &--open {
        background-color: styles.$variables-tertiary-grey !important;
      }
    }
    

    .input {
      .ant-form-item {
        margin: 0;
      }
    }

    .input.navbar-dropdowns {
      .primary-button {
        &.ant-dropdown-button.dropdown-button {
          width: 136px;
        }
      }

      :not(.primary-button) {
        &.ant-dropdown-button.dropdown-button {
          width: 180px;
        }
      }

      .ant-dropdown-button.dropdown-button {
        z-index: 30!important;

        &.primary-button:hover {
          .ant-dropdown-trigger svg {
            color: styles.$variables-white;
          }
        }
      }
    }
  }

  .ant-input-group-addon {
    display: none;
  }

  .ant-select-show-search {
    width: 100%;
  }
}

.ant-dropdown:has(.create-new-dropdown) {
  // NOTE: needed to override inline styles
  top: 50px!important;
  width: 136px;
  z-index: 25;

  .create-new-dropdown {
    border-radius:  0 0 8px 8px;
    padding: 40px 0 0;

    .ant-dropdown-menu-title-content {
      @include styles.mixins-style-font-format(14px, 20px, 400, capitalize);
    }
  }
}
