@use '../../styles';

.settings {
  .ant-tabs {
    height: 50px;

    .ant-tabs-nav {
      .ant-tabs-nav-operations {
        display: none;
      }
    }
  }

  &__subtabs {
    margin-top: 20px;
    min-height: 50px;
    @include styles.mixins-style-flex-row-space-between;

    &-operations {
      flex: 1;
      @include styles.mixins-style-flex-row-end;
    }

    .ant-tabs-tab {
      padding: 5px 20px;

      .ant-tabs-tab-btn {
        margin: 0 auto;
      }
    }

    .ant-tabs-tab-active {
      font-weight: 700;
    }
  }

  .startup-stages__action-buttons {
    @include styles.mixins-style-flex-row-v-center;
    gap: 16px;

    .ant-btn.ghost-button {
      height: auto;
      padding: 0;
      font-weight: 400;
      color: styles.$variables-dark-grey;

      &:hover, &:focus, &:active {
        background: transparent;
        border: none;
      }

      &:not(.delete):hover {
        color: styles.$variables-primary-blue;
      }

      &.delete:hover {
        color: styles.$variables-extended-red;
      }
    }

    .divider {
      @include styles.mixins-style-width-height(1px, 16px);
      background-color: styles.$variables-primary-grey;
    }
  }

    .templates-table {
      margin-top: 27px;
    }

    &__table-name {
      @include styles.mixins-style-font-format(14px, 20px, 700);
      color: styles.$variables-dark-grey;
    }

    &__table-actions {
      @include styles.mixins-style-flex-row-v-center;

      .action-wrap {
        @include styles.mixins-style-flex-row-v-center;
        height: 16px;

        &__label {
          @include styles.mixins-style-font-format(14px, 24px, 400);
          color: styles.$variables-dark-grey;
          margin: 0 0 0 9px
        }

        &__icon {
          cursor: pointer;
          @include styles.mixins-style-flex-column;
          align-items: center;

          svg {
            path {
              fill: styles.$variables-dark-grey;
            }
          }
        }

        &:first-child {
          padding-right: 16px;
        }

        &:nth-child(n + 2) {
          padding: 0 16px;
          @include styles.mixins-style-border(solid, 1px, styles.$variables-secondary-grey, 0, left);                
        }
      }
    }

    &__table-created-date {
        @include styles.mixins-style-flex-row-v-center;
        
        &__value {
          margin: 0;
          @include styles.mixins-style-font-format(14px, 24px, 400);
          color: styles.$variables-dark-grey;
        }
    }
}
