@use '../../styles';

.input-container {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-row-space-between;

    .input-field {
      @include styles.mixins-style-width-height(48%, 100%);
    }

    .input-field-row {
      @include styles.mixins-style-width-height(66%, 100%);
    }
}

.add-service-provider__activation-link-title {
    @include styles.mixins-style-font-format(20px, normal, bold);
    margin-bottom: -5px;
  }