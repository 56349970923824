@use '../../../styles';

.service-requests_list_container {
    padding: 20px 5px;
    max-height: 530px;
    overflow-y: scroll;

    .no_service {
      color: styles.$variables-dark-blue-1;
      @include styles.mixins-style-font-format(16px, normal, 600);
      text-align: center;
    }
}
