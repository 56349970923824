@use '../../styles';

.add-event-drawer {
    min-width: 40vw;

    .ant-btn-link {
        @include styles.mixins-style-flex-row-v-center;
        padding: 0;
        gap: 5px;

        &:hover {
            color: styles.$variables-extended-dark-blue;
        }
    }
}