@use '../../../../styles';

.additional-options-container {
  @include styles.mixins-style-flex-row;

  .additional-options-fields {
    width: 100%;
    @include styles.mixins-style-flex-center;

    .input {

      .ant-form-item {
        margin: 0 0 0 16px;
      }
    }
  }

  .switch-wrap {
    @include styles.mixins-style-flex-center;

    &-label {
      margin: 0 0 0 10px ;
    }
  }
}