@use '../../../../../styles';

.filter-table-drawer {
    .input {
        margin-bottom: 10px;
    }

    td.ant-table-cell {
        cursor: pointer;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      span {
        font-weight: bold !important;
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: styles.$variables-light-blue-1 !important;
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
      background-color: styles.$variables-light-blue-2 !important;
    }
  }

  .filter-table-drawer--hidden-select {
    table tr td.ant-table-selection-column,
    table tr th.ant-table-selection-column {
        display: none;
    }
  }

  .filter-table-drawer--select {
    tr, th {
      display: flex;

      .ant-table-cell {
        flex: 1;
      }

      .ant-table-cell.ant-table-selection-column {
        order: 2;
        flex: 0.1;
      }
    }


    th.ant-table-cell.ant-table-selection-column {
      .ant-table-selection {
        margin: 0 auto;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: styles.$variables-dark-blue-2;
        border-color: styles.$variables-dark-grey;
      }

    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      background-color: styles.$variables-dark-blue-2;
    }

    .add-people-table__actions {
      .ant-btn {
        flex: 0.3;
        min-width: 150px;
      }
    }
  }
