@use '../../../../../styles';

.stat-card {
  @include styles.mixins-style-flex-column;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 16px);
  @include styles.mixins-gradients-blue-gradient;

  &__title,
  &__description,
  &__count,
  &__link {
    margin-bottom: 5px;
  }

  &__icon-container {
    @include styles.mixins-style-width-height(64px, 64px);
    @include styles.mixins-style-flex-center;
    border-radius: 13px;
    background-color: styles.$variables-extended-dark-blue;
    padding: 20px;

    svg {
      @include styles.mixins-style-width-height(20px, 20px);
      color: styles.$variables-extended-dark-blue-stroke;
    }
  }

  &__title {
    @include styles.mixins-style-font-format(16px, normal, bold, capitalize);
  }

  &__count {
    @include styles.mixins-style-font-format(32px, normal, 900, capitalize);
  }

  &__description {
    @include styles.mixins-style-font-format(14px);
  }

  &__title,
  &__count,
  &__description {
    color: styles.$variables-extended-dark-blue;
  }

  &__link {
    @include styles.mixins-style-font-format(12px, normal, bold, uppercase);
  }

  &--green {
    @include styles.mixins-gradients-green-gradient;
    border: 1px solid styles.$variables-extended-green-stroke;
    color: styles.$variables-extended-green;

    .stat-card__title,
    .stat-card__count,
    .stat-card__description {
      color: styles.$variables-extended-green;
    }

    .stat-card__icon-container {
      background-color: styles.$variables-extended-green;
    }

    svg {
      color: styles.$variables-extended-green-stroke;
    }
  }

  &--purple {
    @include styles.mixins-gradients-purple-gradient;
    border: 1px solid styles.$variables-extended-purple-stroke;
    color: styles.$variables-extended-purple;

    .stat-card__title,
    .stat-card__count,
    .stat-card__description {
      color: styles.$variables-extended-purple;
    }

    .stat-card__icon-container {
      background-color: styles.$variables-extended-purple;
    }

    svg {
      color: styles.$variables-extended-purple-stroke;
    }
  }
}


