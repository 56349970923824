@use "../../styles";

.activity-sidebar {
  max-height: 80vh;
  z-index: 9999;
  position: relative;
  overflow-y: scroll;
  @include styles.mixins-style-white-card;
  @include styles.mixins-style-flex-column;

  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  &__header-left,
  &__header-right {
    display: flex;
    gap: 10px;
  }

  &__header-title {
    color: styles.$variables-dark-blue-2;
    font-weight: 600;
  }

  &__no-data {
    min-height: 200px;
    @include styles.mixins-style-flex-center;
  }

  &__bell.ant-btn {
    background-color: styles.$variables-light-blue-2;
    path {
      fill: styles.$variables-dark-blue-2;
      @include styles.mixins-style-width-height(32px, 32px);
      cursor: pointer;
    }
  }

  .show-more-btn {
    margin: 0 auto;
  }
}