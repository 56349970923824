@forward 'variables' as variables-*;
@forward 'mixins' as mixins-*;

@use 'variables';
@use 'mixins';

@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

body {
  margin: 0;
  font-family: 'Mulish', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../assets/images/bg_elements.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.ant-dropdown-menu.dropdown-button {
  .ant-btn.ant-btn-default {
    background: transparent;
    padding: 0;
    height: fit-content;
  }
}

.table-email-column {
  @include mixins.style-font-format(14px, normal, 500, lowercase);
  @include mixins.style-flex-row-v-center;
}

// Antd Button classes
.ant-btn,
.ant-dropdown-button.dropdown-button {
  &.primary-button {
    &:not(.delete-button) {
      @include mixins.style-border(solid, 1px, variables.$primary-blue, 20px);
      background: variables.$primary-blue;
    }

    &, .ant-btn.ant-btn-default {
      color: variables.$white;
    }

    &.delete-button {
      background: variables.$primary-error;
      @include mixins.style-border(solid, 1px, variables.$primary-error, 20px);
    }
  }

  &.secondary-button,
  &.secondary-delete-button {
    @include mixins.style-border(solid, 1px, variables.$tertiary-grey, 20px);
    background: variables.$white;

    &:hover {
      background: variables.$primary-light-blue;
    }

    &:disabled {
      border-color: variables.$primary-grey;
    }

    &:active {
      background: variables.$extended-dark-blue-stroke;
    }
  }

  &.secondary-button {
    &, .ant-btn.ant-btn-default {
      color: variables.$dark-grey;
    }
  }

  &.secondary-delete-button {
    &, .ant-btn.ant-btn-default {
      color: variables.$primary-error;
    }
  }

  &.ghost-button {
    @include mixins.style-border(solid, 1px, transparent, 20px);
    background: transparent;

    &, .ant-btn.ant-btn-default {
      color: variables.$primary-blue;

      &:disabled {
        color: variables.$primary-grey;
      }
    }

  }

    &.danger-button {
    @include mixins.style-border(solid, 1px, variables.$primary-error, 20px);
    background: variables.$primary-error;

    &, .ant-btn.ant-btn-default {
      color: variables.$white;
    }

    &:hover {
      background-color: variables.$red;
      border-color: variables.$red;
    }
  }

  &.primary-button,
  &.ghost-button {
    &:hover {
      @include mixins.style-border(solid, 1px, variables.$secondary-blue, 20px);
      background: variables.$secondary-blue;
    }

    &:active {
      @include mixins.style-border(solid, 1px, variables.$dark-blue, 20px);
      background: variables.$dark-blue;
    }
  }

  &.primary-button,
  &.secondary-button,
  &.secondary-delete-button,
  &.tab-button-sm,
  &.tab-button-lg,
  &.tab-button-md {
    &:disabled {
      background: variables.$secondary-grey;
    }

    &, .ant-btn.ant-btn-default {
      &:disabled {
        color: variables.$primary-grey;
        border-color: variables.$primary-grey;
      }
    }
  }

  &.primary-button,
  &.secondary-button,
  &.ghost-button,
  &.danger-button,
  &.secondary-delete-button {
    @include mixins.style-width-height(fit-content, 40px);
    @include mixins.style-flex-center;
    box-shadow: none;

    &.md {
      padding: 4px 16px;
      @include mixins.style-width-height(fit-content, 32px);
    }

    &:not(.md) {
      padding: 8px 16px;
      @include mixins.style-width-height(fit-content, 40px);
    }

    &, .ant-btn.ant-btn-default {
      @include mixins.style-font-format(14px, 24px, 700, capitalize);
      gap: 8px;
    }

    .ant-btn.ant-btn-default {
      @include mixins.style-flex-row-v-center;
    }
  }

  &.tab-button {
    &-lg, &-sm, &-md {
      @include mixins.style-flex-center;
      flex: 1 1;
      gap: 4px;

      &:not(:first-child):not(:last-child) {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 0, (top, left, bottom));
        border-right: none;
      }

      &:first-child {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 8px 0 0 8px, (top, left, bottom));
        border-right: none;
      }

      &:last-child {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 0 8px 8px 0);
      }

      &:not(:hover):not(:active):not(:disabled):not(.tab-button-active) {
        background: variables.$white;
      }

      &, &:hover {
        color: variables.$primary-blue;
      }

      &:hover {
        background: variables.$extended-active-blue;
      }

      &:active,
      &.tab-button-active {
        color: variables.$white;
        font-weight: 700;
        background: variables.$primary-blue;
        border-color: variables.$primary-blue;
      }
    }

    &-lg {
      @include mixins.style-font-format(14px, 20px, 400);
      padding: 12px 16px;
      height: 40px
    }

    &-md {
      padding: 8px 16px;
      height: 32px;
    }

    &-sm, &-md {
      @include mixins.style-font-format(12px, 16px, 500);
    }

    &-sm {
      padding: 4px 16px;
      height: 24px;
    }
  }

  &.sub-tab {
    @include mixins.style-border(solid, 1px, variables.$tertiary-grey, 0, bottom);
    color: variables.$dark-grey;
    padding: 12px 16px;
    background: transparent;
    box-shadow: none;
    height: 40px;

    .tab-text {
      @include mixins.style-capitalize-first-word-only;
      @include mixins.style-font-format(14px, 20px, 400);
      margin: 0;
    }

    &.active-sub-tab {
      border-bottom-color: variables.$primary-blue;
      color: variables.$primary-blue;
      font-weight: 700;
    }
  }
}

.ant-tabs.ant-tabs-top {
  &.tab-buttons-lg,
  &.tab-buttons-md,
  &.tab-buttons-sm {
    .ant-tabs-nav {
      &, .ant-tabs-nav-wrap {
        &::before {
          content: none;
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-nav-list {
        @include mixins.style-flex-row-v-center;
        @include mixins.style-width-height(100%, 100%);
      }

      .ant-tabs-tab {
        @include mixins.style-flex-center;
        flex: 1 1;
        gap: 4px;

        &:not(:nth-last-child(2)) {
          @include mixins.style-border(none, 0, transparent, 0, (right));

          &:not(:first-child) {
            @include mixins.style-border(solid, 1px, variables.$primary-blue, 0, (top, left, bottom));
          }

          &:first-child {
            @include mixins.style-border(solid, 1px, variables.$primary-blue, 8px 0 0 8px, (top, left, bottom));
          }
        }

        // here we cannot use last-child selector because antd ant-tabs-tab last child doesn't get selected
        &:nth-last-child(2) {
          @include mixins.style-border(solid, 1px, variables.$primary-blue, 0 8px 8px 0);
        }

        &:not(:hover):not(:active):not(.ant-tabs-tab-disabled):not(.ant-tabs-tab-active) {
          background: variables.$white;
        }

        &, &:hover {
          .ant-tabs-tab-btn {
            color: variables.$primary-blue;
          }
        }

        &:hover {
          background: variables.$extended-active-blue;
        }

        &.ant-tabs-tab-active {
          background: variables.$primary-blue;
          border-color: variables.$primary-blue;

          .ant-tabs-tab-btn {
            color: variables.$white;
            font-weight: 700;
          }
        }

        &.ant-tabs-tab-disabled {
          background: variables.$secondary-grey;
          border-color: variables.$primary-grey;

          .ant-tabs-tab-btn {
            color: variables.$primary-grey;
            font-weight: 700;
          }
        }
      }
    }

    &.tab-buttons-lg .ant-tabs-tab {
      padding: 12px 16px;
      height: 40px;

      .ant-tabs-tab-btn {
        @include mixins.style-font-format(14px, 20px, 400);
      }
    }

    &.tab-buttons-md .ant-tabs-tab {
      padding: 8px 16px;
      height: 32px;
    }

    &.tab-buttons-sm,
    &.tab-buttons-md {
      .ant-tabs-tab .ant-tabs-tab-btn {
        @include mixins.style-font-format(12px, 16px, 500);
      }
    }

    &.tab-buttons-sm .ant-tabs-tab {
      padding: 4px 16px;
      height: 24px;
    }
  }
}

.dot {
  @include mixins.style-width-height(2px, 2px);
  background: variables.$dark-grey;
  border-radius: 50%;
}

.ant-btn-link {
  &.dark-blue-link,
  &.add-new-btn {
    color: variables.$dark-blue-2;
    font-weight: bold;
  }

  &.add-new-btn {
    @include mixins.style-flex-row-v-center;
    gap: 10px;
    text-transform: uppercase;

    &:hover {
      color: variables.$light-blue-6;
    }
  }
}

// Animations
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// for centering antd notification
.ant-notification {
  margin-right: 0;
  right: 50% !important;
  transform: translateX(50%);
}

.ant-popover {
  z-index: 15;
}

.spinner {
  @include mixins.style-width-height(100%, 100%);
  @include mixins.style-flex-center;
}

svg {
  stroke-width: 0;
}

// Dropdown menu
.ant-dropdown-menu,
.ant-select-dropdown {
  border-radius: 20px;
  overflow: hidden;
}

.ant-dropdown-menu-item-active {
  background-color: variables.$extended-light-blue !important;
  color: variables.$dark-grey !important;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color:  variables.$dark-blue-2 !important;
  color: variables.$white !important;
}

// Tabs
.ant-tabs-bottom>.ant-tabs-nav:before, .ant-tabs-bottom>div>.ant-tabs-nav:before, .ant-tabs-top>.ant-tabs-nav:before, .ant-tabs-top>div>.ant-tabs-nav:before {
  border-top: 1px solid variables.$tertiary-grey;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: variables.$dark-blue-2;
}
.ant-tabs-tab:hover {
  color: variables.$extended-dark-blue;
}

.ant-tabs-ink-bar-animated {
  background-color: variables.$dark-blue-2;
}

// Tags
.tag {
  &, &-sm {
    &--blue,
    &--teal,
    &--orange,
    &--green,
    &--purple {
      padding: 6px 12px;
      @include mixins.style-font-format(14px, 20px, 500, capitalize);
    }

    &--blue {
      @include mixins.gradients-blue-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-dark-blue-stroke, 20px);
      color: variables.$extended-dark-blue;
    }

    &--green {
      @include mixins.gradients-green-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-green-stroke, 20px);
      color: variables.$extended-green;
    }

    &--purple {
      @include mixins.gradients-purple-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-purple-stroke, 20px);
      color: variables.$extended-purple;
    }

    &--orange {
      @include mixins.gradients-orange-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-orange-stroke, 20px);
      color: variables.$extended-orange;
    }

    &--teal {
      @include mixins.gradients-teal-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-teal-stroke, 20px);
      color: variables.$extended-teal;
    }
  }

  &-sm {
    &--blue,
    &--teal,
    &--orange,
    &--green,
    &--purple {
      border-radius: 16px;
      padding: 3px 12px;
    }
  }
}

// Item validation error jumping fix
.ant-form-item-explain-error {
  position: absolute;
}

.not-found {
  @include mixins.style-width-height(100%, 100%);
  @include mixins.style-flex-center-column;
  @include mixins.style-font-format(36px, 48px, 700);
  color: variables.$extended-red;
  gap: 16px;

  .secondary-button {
    text-transform: none;
  }
}
