@use '../../styles';

.service-provider-details {
  @include styles.mixins-style-flex-column;
  gap: 16px;
  padding-bottom: 20px;

  &__requests {
    .requests {
      .overview {
        margin-bottom: 0;
        background-color: styles.$variables-light-blue-1;
      }
    }
  }

  .ant-collapse {
    background-color: transparent;
  }

  .view-all-button {
    background-color: styles.$variables-light-blue-3;
    @include styles.mixins-style-border(solid, 0, transparent, 8px);
    @include styles.mixins-style-font-format(12px, normal, bold, uppercase);
    float: right;
    padding: 10px;
  }

  .about-service__company-deck {
    .company-deck-file {
      width: 100%;

      &__buttons {
        @include styles.mixins-style-flex-row;
        gap: 10px;
      }
    }
  }
}
