@use '../../../../styles';

.service-provider-details {
  &__info {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-row-space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    @include styles.mixins-style-white-card;

    .overview {
      margin-left: 20px;
    }
  }

  &__subtitle {
    @include styles.mixins-style-font-format(16px, normal, bold, capitalize);
    color: styles.$variables-dark-grey;
    margin: 20px 0 10px;
    text-align: start;
  }

  &__overview-row {
    @include styles.mixins-style-flex-row;
    flex-wrap: wrap;
    width: 100%;

    :not(:last-child) {
      margin-bottom: 20px;
    }

    .overview-data-column {
      flex: 1 1 50%;
    }
  }
}
