@use '../../../../styles';

.attributes {
  margin: 16px 0 10px;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-2, 16px);
  @include styles.mixins-style-flex-column;
  background-color: styles.$variables-white;
  float: left;
  width: 100%;
  padding: 16px;
  gap: 8px;
  box-shadow: 0 4px 4px rgba(47, 64, 81, 0.07);

  &__title {
    @include styles.mixins-style-font-format(14px, 20px, 600, uppercase, normal, 'Cairo');
    margin: 0;
    opacity: 0.8;
    color: styles.$variables-dark-grey;
  }

  &__list {
    @include styles.mixins-style-flex-row;
    flex-wrap: wrap;
    gap: 4px;
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(12px, 18px, 400);
  }
}
