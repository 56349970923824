@use '../../../../styles';

.articles-overview {
  --grey-light: #6B6B6B;
  --black-light: #242424;
  --size-six: clamp(0.125rem, 0.0957rem + 0.1286vw, 0.25rem);
  --size-thirteen: clamp(0.75rem, 0.7353rem + 0.0643vw, 0.8125rem);
  height: 100%;

  .articles-container {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-column;
    align-items: center;
    gap: clamp(0.875rem, 0.8457rem + 0.1286vw, 1rem);
    padding-bottom: clamp(1.5rem, 1.412rem + 0.3859vw, 1.875rem);

    .article-row {
      width: 100%;
      background: styles.$variables-white;
      padding: clamp(0.875rem, 0.8457rem + 0.1286vw, 1rem);
      border-radius: 1rem;
      box-shadow: 0 24px 24px rgba(47, 64, 81, 0.07);

      .article-row__info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;

        .article-row__info-container--left {
            flex-grow: 1;
            height: clamp(7.5rem, 7.2946rem + 0.9003vw, 8.375rem);
            padding: 1rem;

            .article-author-source-container {
                display: flex;
                gap: var(--size-six);
                align-items: center;
                width: 100%;
              
                .article-author, .article-source {
                    p {
                        color: var(--black-light);
                        font-size: var(--size-thirteen);
                        font-weight: 600;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            h2 {
                font-size: clamp(1.125rem, 1.0957rem + 0.1286vw, 1.25rem);
                font-weight: 700;
                margin-bottom: 0;
                text-overflow: ellipsis;
                line-height: 24px;
                max-height: clamp(2.875rem, 2.8457rem + 0.1286vw, 3rem);
                color: #0062ed;
            }

            .article-description {
                padding-top: clamp(0.1875rem, 0.1728rem + 0.0643vw, 0.25rem);
                p {
                    max-height: clamp(2.25rem, 2.1913rem + 0.2572vw, 2.5rem);
                    color: var(--grey-light);
                    font-weight: 400;
                    text-overflow: ellipsis;
                    width: 50%;
                    overflow: hidden;
                    line-height: 20px;
                }
            }
            
            .article-short-description  {
                p {
                    color: var(--black-light);
                    font-size: 1rem;
                    line-height: 20px;
                    margin: 0.5rem 0;
                }
            }
        }
        
        .article-row__info-container--right {

            .article-row__info-container__logo {
                height: clamp(7.5rem, 7.2946rem + 0.9003vw, 8.375rem);
                width: clamp(11.875rem, 11.7283rem + 0.6431vw, 12.5rem);
                object-fit: cover;
                object-position: center;
            }
        }

        .article-row__request-info--row {
            padding-top: clamp(0.375rem, 0.3457rem + 0.1286vw, 0.5rem);
            span {
                font-size: var(--size-thirteen);
                color: var(--grey-light);
            }
        }
      }
    }
  }
}
