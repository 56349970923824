@use '../../../../styles';

.adgm-license-details-drawer {
  @include styles.mixins-style-flex-column;
  gap: 8px;
  position: relative;

  &__status {
    margin-bottom: 30px;
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
  }
}
