@use '../../../../../../styles';

.ant-modal-wrap .ant-modal.ac-letter-preview {
  .ant-modal-footer {
    @include styles.mixins-style-border(none);
    background-color: styles.$variables-light-blue-2;
    padding: 30px 25%;

    .footer {
      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 46.5px;
      }
    }
  }

  .ant-modal-content {
    background-color: styles.$variables-light-blue-1;
  }

  .ant-modal-body {
    padding: 0;
  }
}

.al-pdf-preview {
  &__container {
    @include styles.mixins-style-width-height(100%, 100%);
    // padding: 0 20% 0;

    .rpv-core {
      &__inner-pages {
        padding: 25px 0;
      }

      &__inner-page {
        background-color: transparent;
      }

      &__canvas-layer canvas {
        background-color: styles.$variables-light-blue-1;
      }

      &__page-layer {
        &:after {
          @include styles.mixins-style-border(solid, 1px, styles.$variables-light-blue-3);
          @include styles.mixins-style-edges(0.5rem, 0.5rem, 0.5rem, 0.5rem);
          @include styles.mixins-style-box-shadow(0.5rem, 1rem, 1.5rem, 2rem, styles.$variables-light-blue-1);
        }
      }
    }
  }
}
