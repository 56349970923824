@use '../../styles';

.cohort-details {
  @include styles.mixins-style-flex-column;
  padding-bottom: 20px;
  gap: 16px;

  .acceptanceLetter-ADGM {
    margin-top: 20px;
  }
}
