@use '../../../../styles';

.filter-tag {
  margin-bottom: 25px;
  width: fit-content;

  .ant-tag{
    @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
    @include styles.mixins-style-font-format(14px, 22px, 500, capitalize);
    @include styles.mixins-style-flex-center;
    background-color: styles.$variables-white;
    color: styles.$variables-primary-blue;
    padding: 0 8px;
    margin: 4px;
    gap: 8px;

    .ant-tag-close-icon {
      color: styles.$variables-primary-blue;
      margin-top: 8px;
    }
  }
}
