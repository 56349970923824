@use '../../../../../styles';

.custom-pagination {
  @include styles.mixins-style-flex-row-v-center;
  width: 100%;
  gap: 6px;
  margin-top: 20px;

    &__heading {
      width: 115px;
      @include styles.mixins-style-font-format(14px, 20px, 500);
      color: styles.$variables-dark-grey;
      margin: 0;
    }

  .ant-pagination {
    @include styles.mixins-style-flex-row;
    width: calc(100% - 115px);

    .ant-pagination-options {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      order: -1;
      margin: 0 auto 0 0;

      .ant-select-selector {
        @include styles.mixins-style-width-height(65px, 32px);
        @include styles.mixins-style-border(none, 0, transparent, 20px);
        background: styles.$variables-white;
      }
    }

    .ant-pagination-item {
      @include styles.mixins-style-width-height(32px, 32px);
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);

      a {
        @include styles.mixins-style-font-format(16px, 30px, 400);
        font-family: 'Mulish', sans-serif;
        color: styles.$variables-dark-grey;
      }

      &:hover {
         @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
         background: styles.$variables-extended-active-blue;;

        a {
          color: styles.$variables-dark-grey !important;
        }
      }
    }

    .ant-pagination-item-active{
      background: styles.$variables-primary-blue;
      border: none;

      a {
        color: styles.$variables-white !important;;
      }

      &:hover {
        background: styles.$variables-primary-blue;
        border: none;

        a {
          color: styles.$variables-white !important;;
        }
      }
    }

    .ant-pagination-item-ellipsis {
      color: styles.$variables-extended-dark-blue-stroke;

      &:active, &:focus {
        background: none;
      }
    }

    .ant-pagination-next, .ant-pagination-prev {

      .ant-pagination-item-link {
        @include styles.mixins-style-width-height(32px, 32px);
        @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);

        svg {
          fill: styles.$variables-dark-grey;
        }

        &:hover {
          @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 16px);
        }

        &:disabled {
          svg {
            fill: styles.$variables-extended-dark-blue-stroke;
          }
        }
      }

    }
  }
}
