@use '../../../../../../styles';

.ant-popover.popover-inner-style {
  z-index: 100;
}

.ant-popover-inner {
  margin-right: 15px;

  .profile-content-col {
    @include styles.mixins-style-flex-column;
    gap: 8px;
    font-weight: 700;
    padding: 5px;

    .avatar-options {
      color: styles.$variables-primary-blue;
      cursor: pointer;
    }

    .sign-out {
      color: styles.$variables-primary-error;
      cursor: pointer;
    }
  }
}

.ant-avatar.avatar {
  @include styles.mixins-style-width-height(40px, 40px);
  cursor: pointer;
}
