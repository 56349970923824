@use '../../../../../../styles';

.documents-tab-content {
   height: 100%;

   h3{
      margin: 0 0 20px 0;
   }
   
   .add-document-container{
      margin-top: 15px;

      .add-document-fields {
         @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 8px);
         @include styles.mixins-style-flex-row-space-between;
         padding: 10px 20px;
         
         .add-input-field {
            max-width: 200px;
            margin-right: 10px;
            border-radius: 0 !important;
         }

         .ant-upload-drag {
            max-width: 180px;
            margin-left: 10px;
            border-radius: 0;
         }

         .uploaded-file {
            max-width: 220px;
            margin-left: 10px;

            .file-info--container{
               overflow: hidden;
            }
         }
       
         .add-action-btns {
            display: flex;
            justify-content: center;
            flex-direction: column;
            button {
               margin: 10px;
            }

            .discard-btn {
               &:hover {
                 color: #bb2124;
                 border-color: #bb2124; 
               }
            }
         }
      }

      .form-error {
         color: #bb2124;
      }

      .add-doc-button {
         height: 60px;
         width: 180px;
         margin-top: 15px;
      }
   }
}