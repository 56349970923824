@use '../../../../../../../styles';

.uploaded-file {
  @include styles.mixins-style-flex-row-space-between;
  @include styles.mixins-style-border(dashed, 1px, styles.$variables-dark-grey, 8px);
  padding: 10px;
  flex: auto;
  flex-wrap: wrap;

  &__documents {
    @include styles.mixins-style-width-height(100%, 42px);
    background-color: styles.$variables-white;
    border: 1px solid #CFD7E0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .file-info {
    @include styles.mixins-style-flex-row;
    align-items: center;
    @include styles.mixins-style-width-height(100%, auto);
    flex: 1;
    flex-wrap: wrap;

    &--container {
      @include styles.mixins-style-flex-column;
      justify-content: flex-start;
      width: 100%;
    }

    .file-name {
      text-align: start;
      @include styles.mixins-style-font-format(14px, normal, bold, capitalize);
      color: styles.$variables-dark-grey;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-size-container {
      @include styles.mixins-style-width-height(100%, auto);
      min-height: 40px;
      @include styles.mixins-style-font-format(14px, normal, bold, uppercase);
      color: styles.$variables-tertiary-grey;
      text-align: start;
    }
  }

  .file-icon {
    @include styles.mixins-style-width-height(63px, 69px);
  }
}

