@use '../../../../styles';

.service-provider-details {
    .overview {
        background-color: transparent;
        .overview-data-label, .overview-data-text {
            color: styles.$variables-dark-grey;
        }
    }
}
