@use '../../../../../../styles';

.incentive-requested-by {
    margin-bottom: 40px;

    &__title {
        color: styles.$variables-dark-blue-2;
        @include styles.mixins-style-font-format(20px, normal, bold);
    }

    &__container {
        @include styles.mixins-style-light-blue-card;
        display: flex;
        justify-content: space-between;
    }

    &__info-container {
        display: flex;
    }

    &__company-name {
        margin-left: 5px;
    }

    &__company-name,
    &__role {
        color: styles.$variables-dark-blue-2;
    }

    &__email,
    &__phone {
        color: styles.$variables-dark-blue-2;
        text-decoration: underline;
    }
}