@use '../../../../styles';

.requests-overview {
  height: 100%;

  .requests-by-category {
    @include styles.mixins-style-flex-column;
    gap: 24px;
  }

  .requests-container {
    @include styles.mixins-style-width-height(100%, auto);
    @include styles.mixins-style-flex-column;
    align-items: center;
    gap: 16px;
    padding-bottom: 30px;

    &:not(:has(.unsent-requests)) {
      min-height: calc(100% - 170px);

      .request-row {
        box-shadow: styles.$variables-vertical-box-shadow;
      }
    }

    .request-row {
      @include styles.mixins-style-flex-row-space-between;
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
      width: 100%;
      background: styles.$variables-white;
      padding: 16px;

      &.highlighted-row {
        border-color: styles.$variables-primary-success;
      }

      &__status-container {
        .ant-btn.request-row__denied-see-why {
          @include styles.mixins-style-font-format(14px, 20px, 400);
          @include styles.mixins-style-transparent-button;
          color: styles.$variables-primary-info;
          width: fit-content;
          padding: 0;
          height: auto;

          // NOTE: antd button contains span without classname, inside of which children are placed.
          span {
            text-decoration-line: underline;
          }
        }
      }

      &__buttons-container,
      &__status-container {
        @include styles.mixins-style-flex-row-v-center;
        gap: 16px;
      }

      &__right-container {
        @include styles.mixins-style-flex-row;
      }

      &__left-container {
        @include styles.mixins-style-flex-column;
        gap: 4px;
      }

      &__request-info {
        @include styles.mixins-style-flex-column;

        &__unsent-request--quantity, &--row__details {
          color: styles.$variables-dark-grey;
          @include styles.mixins-style-font-format(12px, 16px, 300);
        }

        &--row {
          @include styles.mixins-style-flex-row-v-center;
          gap: 4px;

          &.unsent {
            .request-row__request-info--row__details {
              @include styles.mixins-style-font-format(14px, 20px, 700);
            }
          }
        }
      }

      &__status {
        @include styles.mixins-style-font-format(14px, 16px, 400);
        @include styles.mixins-style-capitalize-first-word-only;
        margin: 0;
      }
    }
  }
}
