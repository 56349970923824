@use '../../../../../styles';

.input {
  label.input-radio {
    &.ant-radio-wrapper {
      @include styles.mixins-style-font-format(14px, 22px, 400);
      color: styles.$variables-dark-grey;
    }

    .ant-radio-inner {
      border-color: styles.$variables-primary-blue;

      &::after {
        background-color: styles.$variables-primary-blue;
      }
    }
  }
}
