@use '../../../styles';

.add-to-list {
  &__input {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .ant-btn.ant-btn-icon-only, .ant-btn-ghost {
    height: auto;
    padding: 0 !important;
    font-weight: 700;
    color: styles.$variables-dark-grey;
    border: none !important;

    &:hover, &:focus, &:active {
      background: transparent;
    }

    &:not(.delete):hover {
      color: styles.$variables-primary-blue;
    }

    &.delete {
      color: styles.$variables-primary-error;
    }

    &.delete:hover {
      color: styles.$variables-extended-red;
    }
  }

  &__input {
    .ant-btn-ghost {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
