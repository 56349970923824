@use "../../../../styles";

.notification {
  display: flex;
  gap: 10px;
  color: styles.$variables-dark-grey;
  margin-bottom: 30px;
  animation: fade-in 0.4s normal;

  &__info {
    flex: 1;
  }

  &__info-header {
    @include styles.mixins-style-flex-row-space-between;
    gap: 20px;
  }

  &__description {
    font-weight: 700;
  }

  &__time-ago {
    color: styles.$variables-light-blue-6;
    white-space: nowrap;
  }

  &__info-card {
    @include styles.mixins-style-white-card;

    h4 {
      color: styles.$variables-light-blue-6;
      text-align: left;
      text-transform: uppercase;
    }
  }

  &__modifications {
    display: flex;
    gap: 10px;
  }

  .ant-avatar-sm {
    line-height: unset;
    @include styles.mixins-style-width-height(30px, 30px);
    background-color: transparent;
  }
  .ant-avatar-sm.ant-avatar-icon {
    font-size: 20px;
    color: styles.$variables-light-blue-1;
  }
}
